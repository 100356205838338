import React, { useRef, useState } from 'react';

import { useOnClickOutside } from '../../../hooks';
import Burguer from './Burguer';
import Menu from './Menu';

const BurguerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const close = () => setIsOpen(false);

  const node = useRef();
  useOnClickOutside(node, close);

  return (
    <div ref={node}>
      <Burguer isOpen={isOpen} setIsOpen={setIsOpen} />
      <Menu isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default BurguerMenu;
