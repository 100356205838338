import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import map from 'lodash/map';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Container, DockingButton, Paper } from '../../components';
import { useActiveContext } from '../../hooks';
import { useGroups } from '../../store/Groups/selectors';
import { useOrganizations } from '../../store/Organizations/selectors';

const Subtitle = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`;

const ContinueButton = styled(DockingButton)`
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`;

const SetActiveContext = () => {
  const history = useHistory();
  const {
    activeOrganization,
    activeGroup,
    setActive,
    isLoading,
  } = useActiveContext();
  const [localState, setLocalState] = useState({
    activeOrganization,
    activeGroup,
  });
  const organizations = useOrganizations();
  const { groups = [] } = organizations[localState.activeOrganization] || {};
  const allGroups = useGroups();

  const filteredGroups = useMemo(() => {
    return groups.reduce((acc, groupId) => {
      const group = allGroups[groupId];
      const isTopLevelGroup = !group?.parentId;
      const orgMatches = group?.orgId === localState.activeOrganization;
      if (group && isTopLevelGroup && orgMatches) {
        return { ...acc, [group.id]: group };
      }
      return acc;
    }, {});
  }, [groups, allGroups, localState]);

  const validFields = Boolean(activeOrganization);

  const handleChange = ({ target: { name, value } }) =>
    setLocalState({
      ...localState,
      [name]: value,
    });

  const onSubmit = () => {
    if (validFields) {
      setActive(localState);
      history.push('/');
    }
  };

  return (
    <Container>
      <Paper square>
        <Typography variant="h5">Set Active Team</Typography>
        <Subtitle align="center">
          Select the team that you’d like to manage.
        </Subtitle>
        <FormControl>
          <InputLabel id="org-select">Organization</InputLabel>
          <Select
            labelId="org-select"
            name="activeOrganization"
            value={localState.activeOrganization}
            onChange={handleChange}
          >
            {organizations != null &&
              map(organizations, ({ id, name }) => (
                <MenuItem key={`org-${id}`} value={id}>
                  {name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="team-select">Team</InputLabel>
          <Select
            labelId="team-select"
            name="activeGroup"
            value={localState.activeGroup}
            onChange={handleChange}
          >
            {!!groups.length &&
              map(filteredGroups, ({ id, name }) => (
                <MenuItem key={`group-${id}`} value={id}>
                  {name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <ContinueButton onClick={onSubmit} disabled={isLoading || !validFields}>
          Continue
        </ContinueButton>
      </Paper>
    </Container>
  );
};

export default SetActiveContext;
