import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  height: 48px;
`;

const BaseButton = ({ children, isLoading, disabled, onClick, className }) => (
  <StyledButton disabled={disabled} onClick={onClick} className={className}>
    {isLoading ? <CircularProgress size={25} /> : children}
  </StyledButton>
);

BaseButton.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.any,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.any.isRequired,
};

BaseButton.defaultProps = {
  isLoading: false,
  children: null,
  onClick: () => {},
  disabled: false,
};

export default BaseButton;
