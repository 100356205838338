import PropTypes from 'prop-types';
import React from 'react';

import BurguerIcon from './BurguerIcon';

const Burger = ({ isOpen, setIsOpen }) => {
  return (
    <BurguerIcon isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
      <div />
      <div />
      <div />
    </BurguerIcon>
  );
};

Burger.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default Burger;
