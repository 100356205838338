import { useTheme } from '@material-ui/core/styles';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { PATHS } from '../../constants';
import { useStripeSubscriptions } from '../../store/StripeSubscriptions/selectors';
import {
  useCreateOrgSubscription,
  useFetchStripeSubscriptions,
} from '../../store/StripeSubscriptions/thunks';
import { SubmitButton, Subtitle } from './SignupComponents';
import SubscriptionItem from './SubscriptionItem';

const CardElementContainer = styled.div`
  display: flex;
  width: 400px;
  height: 60px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.palette.secondary.main};
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

const StyledCardElement = styled(CardElement)`
  width: 400px;
`;

const Payment = ({ form, showSuccessModal, setError }) => {
  const stripe = useStripe();
  const elements = useElements();
  const stripeSubscriptions = useStripeSubscriptions();
  const theme = useTheme();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [cardComplete, setCardComplete] = useState(false);

  const subscriptions = Object.values(stripeSubscriptions);
  const activeSubscription = (subscriptions.length && subscriptions[0]) ?? {};
  const fetchStripeSubscriptions = useFetchStripeSubscriptions();
  const createOrgSubscription = useCreateOrgSubscription();

  useEffect(() => {
    if (!activeSubscription) {
      fetchStripeSubscriptions();
    }
  }, [activeSubscription, fetchStripeSubscriptions]);

  const cardElementStyle = {
    style: {
      base: {
        color: theme.palette.primary.main,
        fontSmoothing: 'antialiased',
        fontSize: '20px',
        '::placeholder': {
          color: theme.palette.text.primary,
        },
      },
      invalid: {
        color: theme.palette.error.main,
      },
    },
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements && elements.getElement(CardElement),
      });

      const { error } = await createOrgSubscription({
        orgId: form.orgId,
        priceId: activeSubscription.id,
        paymentMethodId: paymentMethod.id,
      });

      if (error) {
        throw new Error();
      }

      showSuccessModal();
      history.push(`/${PATHS.ORGANIZATIONS}`);
    } catch {
      setError('Your payment was declined.');
    } finally {
      setIsLoading(false);
    }
  };

  const isButtonDisabled = !stripe || isLoading || !cardComplete;

  return (
    <>
      <Subtitle variant="h6">Payment Details</Subtitle>
      <SubscriptionItem
        name={activeSubscription.subscriptionName}
        price={activeSubscription.unit_amount}
      />
      <CardElementContainer>
        <StyledCardElement
          options={cardElementStyle}
          onChange={(e) => {
            setCardComplete(e.complete);
          }}
        />
      </CardElementContainer>
      <SubmitButton
        disabled={isButtonDisabled}
        isLoading={isLoading}
        onClick={handleSubmit}
      >
        Pay and Complete
      </SubmitButton>
    </>
  );
};

Payment.propTypes = {
  form: PropTypes.object.isRequired,
  showSuccessModal: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
};

export default Payment;
