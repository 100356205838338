import React, { useContext, useState } from 'react';

import { Container, Header, NavBar } from '../../components';
import { Form, SubmitButton, TextInput } from '../../components/inputs';
import { AlertContext } from '../../contexts';
import { useSubmitContact } from '../../hooks';
import { useCurrentUserId } from '../../utils/hooks';

const initialFormState = {
  name: '',
  email: '',
  comment: '',
};

const Contact = () => {
  const userId = useCurrentUserId();
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(initialFormState);
  const { showModal } = useContext(AlertContext);
  const submitContact = useSubmitContact();

  const onFormInputValueChange = ({ target: { id, value } }) =>
    setForm({
      ...form,
      [id]: value,
    });

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await submitContact(form);
      setForm(initialFormState);
      showModal({
        severity: 'success',
        message: 'Your contact email was sent successfully.',
      });
    } catch (err) {
      showModal({
        severity: 'error',
        message: 'We were unable to send your contact email.',
      });
    }
    setIsLoading(false);
  };

  const { name, email, comment } = form;
  const isButtonDisabled =
    name === '' || email === '' || comment === '' || isLoading;

  return (
    <>
      {userId !== null && <NavBar />}
      <Container>
        <Form isLoading={isLoading}>
          <Header subtext="Use this form to contact TotalTeam with questions or support inquiries." />
          <TextInput
            label="Name"
            id="name"
            value={name}
            onChange={onFormInputValueChange}
          />
          <TextInput
            label="Email"
            id="email"
            value={email}
            onChange={onFormInputValueChange}
          />
          <TextInput
            label="Comment"
            id="comment"
            value={comment}
            onChange={onFormInputValueChange}
            multiline
            rows={4}
          />
          <SubmitButton onClick={handleSubmit} isDisabled={isButtonDisabled}>
            Submit
          </SubmitButton>
        </Form>
      </Container>
    </>
  );
};

export default Contact;
