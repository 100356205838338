import { Avatar } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import missingImage from '../images/account-circle.svg';

const ImageComponent = styled(Avatar).attrs(() => ({
  variant: 'rounded',
}))`
  height: 50px;
  width: 50px;
  border-radius: 50px;
  margin-right: 20px;
`;

const Image = ({ src }) => <ImageComponent src={src ?? missingImage} />;

Image.propTypes = { src: PropTypes.string };
Image.defaultProps = { src: null };

export default Image;
