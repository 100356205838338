import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import styled from 'styled-components';

import { Paper } from '../../components';
import { ConstantsContext } from '../../contexts';
import {
  useDateRequired,
  useDateTaken,
  useSurveyById,
  useSurveyPolicies,
} from '../../store/Surveys/selectors';
import { getFormattedDate } from '../../utils/transform';

const Subtitle = styled(Typography)`
  margin-top: -${({ theme: { spacing } }) => spacing(1)}px;
  margin-bottom ${({ theme: { spacing } }) => spacing(3)}px;
`;

const CompletedTable = styled(Table)`
  border-radius: 9px;
  overflow: hidden;
  margin-top: ${({ theme }) => theme.spacing(4)}px;
  margin-bottom: ${({ theme }) => theme.spacing(8)}px;
  margin-left: auto;
  margin-right: auto;
  max-width: ${({ theme }) => theme.breakpoints.values.sm}px;
`;

const CompletedTableHead = styled(TableHead)``;

const CompletedHeadCell = styled(TableCell)`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 1rem;
  font-weight: bold;
`;

const CompletedTableBody = styled(TableBody)``;

const Taken = styled(Typography)`
  text-align: center;
  font-weight: bold;
`;

const StatusBadge = styled(Chip)`
  background-color: ${({ background }) => background};
  width: 100%;
  color: ${({ theme }) => theme.palette.common.white};
  font-weight: bold;
`;

const Completed = ({ surveyId }) => {
  const { constants } = useContext(ConstantsContext);
  const { genericStatuses } = useSurveyById(surveyId);
  const policies = useSurveyPolicies(surveyId);
  const dateRequired = useDateRequired(surveyId);
  const todaysDate = getFormattedDate(dateRequired);

  const dateTaken = useDateTaken(surveyId);

  const { statusKeys, symptomStatuses, availabilityStatuses } = constants;

  const symptomStatusValue = genericStatuses[statusKeys.SYMPTOMS];
  const availabilityStatusValue = genericStatuses[statusKeys.AVAILABILITY];

  const symptomStatusConfig = symptomStatuses[symptomStatusValue];
  const availabilityStatusConfig =
    availabilityStatuses[availabilityStatusValue];

  const symptomStatusLabel = symptomStatusConfig.displayName;
  const availabilityStatusLabel = availabilityStatusConfig.displayName;

  const symptomStatusColor = symptomStatusConfig.color;
  const availabilityStatusColor = availabilityStatusConfig.color;

  const symptomStatusPolicy = policies[statusKeys.SYMPTOMS][symptomStatusValue];
  const availabilityStatusPolicy =
    policies[statusKeys.AVAILABILITY][availabilityStatusValue];

  const formattedDate = moment(dateTaken).format('h:mm A M/D/YYYY');

  return (
    <Paper square>
      <Typography variant="h5">Daily Wellness Survey</Typography>
      <Subtitle variant="h6">{todaysDate}</Subtitle>
      <CompletedTable>
        <CompletedTableHead>
          <TableRow>
            <CompletedHeadCell>Your Statuses</CompletedHeadCell>
            <CompletedHeadCell>Team Policy</CompletedHeadCell>
          </TableRow>
        </CompletedTableHead>
        <CompletedTableBody>
          <TableRow>
            <TableCell>
              <StatusBadge
                label={symptomStatusLabel}
                background={symptomStatusColor}
              />
            </TableCell>
            <TableCell>
              <Typography>{symptomStatusPolicy}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <StatusBadge
                label={availabilityStatusLabel}
                background={availabilityStatusColor}
              />
            </TableCell>
            <TableCell>
              <Typography>{availabilityStatusPolicy}</Typography>
            </TableCell>
          </TableRow>
        </CompletedTableBody>
      </CompletedTable>
      <Taken>{`Completed at ${formattedDate}`}</Taken>
    </Paper>
  );
};

Completed.propTypes = {
  surveyId: PropTypes.number.isRequired,
};

export default Completed;
