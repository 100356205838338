import Container from '@material-ui/core/Container';
import jwt from 'jsonwebtoken';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { DynamicFields, Header } from '../../components';
import { Form, SubmitButton } from '../../components/inputs';
import { ALERT_SEVERITY } from '../../constants/index';
import { AlertContext } from '../../contexts';
import { fetch, initializeAxios, post } from '../../utils/api';

function LabelsView({ location }) {
  const [customLabels, setCustomLabels] = useState([]);
  const [customLabelValues, setCustomLabelValue] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  const { showModal } = useContext(AlertContext);

  const { token } = Object.fromEntries(new URLSearchParams(location.search));

  // Decode JWT
  const { orgId, groupId, groupName, labels: labelIds } =
    jwt.decode(token) || {};

  const showErrorModal = useCallback(
    () =>
      showModal({
        message: 'Oops! Something went wrong',
        severity: ALERT_SEVERITY.ERROR,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const showSuccessModal = () =>
    showModal({ message: 'Done!', severity: ALERT_SEVERITY.SUCCESS });

  const getTitle = () => `
    ${groupName} is requesting the following information. Please type N/A for fields that don't apply.
  `;

  const fetchCustomLabels = useCallback(async () => {
    try {
      const { labels = [] } = await fetch(
        `/organizations/${orgId}/groups/${groupId}/labels`,
        {
          labelIds,
        },
      );
      setCustomLabels(labels);
    } catch (e) {
      showErrorModal();
      setCustomLabels([]);
    } finally {
      setIsLoading(false);
    }
  }, [showErrorModal, setCustomLabels, orgId, groupId, labelIds]);

  useEffect(() => {
    fetchCustomLabels();
  }, [fetchCustomLabels]);

  const clearFields = () => {
    setCustomLabelValue({});
  };

  const handleSubmit = async () => {
    try {
      initializeAxios(token);
      setIsLoading(true);
      await post(`/organizations/${orgId}/groups/${groupId}/labels/data/bulk`, {
        labels: customLabelValues,
      });
      clearFields();
      showSuccessModal();
    } catch {
      showErrorModal();
    } finally {
      setIsLoading(false);
    }
  };

  const onCustomLabelValueChange = ({ id, value }) => {
    setCustomLabelValue({
      ...customLabelValues,
      [id]: value,
    });
  };

  const dynamicFieldsEmpty = customLabels.some(
    ({ id }) => !customLabelValues[id],
  );

  const isButtonDisabled = dynamicFieldsEmpty || isLoading;

  return (
    <Container maxWidth="sm">
      <Form isLoading={isLoading}>
        <Header subtext={getTitle()} />
        <DynamicFields
          fields={customLabels}
          values={customLabelValues}
          onValueChange={onCustomLabelValueChange}
        />
        <SubmitButton onClick={handleSubmit} isDisabled={isButtonDisabled}>
          Submit
        </SubmitButton>
      </Form>
    </Container>
  );
}

LabelsView.propTypes = {
  location: PropTypes.object.isRequired,
};

export default LabelsView;
