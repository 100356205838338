import reduce from 'lodash/reduce';
import { useMemo } from 'react';

import { PATHS } from '../constants';
import { useOrganizations } from '../store/Organizations/selectors';
import { useTeamRosterForGroupUser } from '../store/TeamRosters/selectors';
import { useCurrentUserId } from '../utils/hooks';
import { useActiveContext } from './useActiveContext';
import { useRequest } from './useRequest';

const { CONTACT, SURVEY, SET_ACTIVE_CONTEXT, ORGANIZATIONS, THEME } = PATHS;

const NAV_LINKS = [
  { title: 'Theme', path: `/${THEME}` },
  { title: 'Set Active Team', path: `/${SET_ACTIVE_CONTEXT}` },
  { title: 'Contact', path: `/${CONTACT}` },
];

export const useNavLinks = () => {
  const { activeGroup: groupId } = useActiveContext();
  const activeOrganizations = useOrganizations();

  const { data = [] } = useRequest('/organizations/inactive');

  const inactiveOrganizations = data.reduce(
    (acc, current) => ({ ...acc, [current.id]: current }),
    {},
  );

  const organizations = { ...activeOrganizations, ...inactiveOrganizations };

  const userId = useCurrentUserId();

  const adminIds = useMemo(() => {
    return reduce(
      organizations,
      (acc, { admin = [] }) => [...acc, ...admin.map(({ id }) => id)],
      [],
    );
  }, [organizations]);

  const isAdmin = adminIds.includes(userId);
  const teamRosterId = useTeamRosterForGroupUser({ userId, groupId });
  const isTeamMember = !!teamRosterId;

  const links = [];
  if (isAdmin) {
    const { length: organizationsCount } = Object.keys(organizations);
    const title =
      organizationsCount === 1 ? 'My Organization' : 'Organizations';
    links.push({ title, path: `/${ORGANIZATIONS}` });
  }

  if (isTeamMember) {
    // All roles included
    links.push({ title: 'Wellness', path: `/${SURVEY}` });
  }

  return [...links, ...NAV_LINKS];
};
