import { useSelector } from 'react-redux';

import { selectors } from './slice';

const { selectEntities, selectTeamRostersStatus, selectTotal } = selectors;

export const useTeamRosters = () => useSelector(selectEntities);
export const useTeamRostersStatus = () => useSelector(selectTeamRostersStatus);
export const useTeamRostersTotal = () => useSelector(selectTotal);

export const useTeamRosterForGroupUser = ({ userId, groupId }) => {
  const teamRosters = useTeamRosters();
  const teamRostersMap = Object.values(teamRosters);

  const { id: teamRosterId } =
    teamRostersMap.find(
      (teamRoster) =>
        userId === teamRoster.userId && groupId === teamRoster.groupId,
    ) || {};

  return teamRosterId;
};
