import { createAsyncThunk } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { fetch, post } from '../../utils/api';
import { name } from './schema';

export const fetchStripeSubscriptions = createAsyncThunk(
  `${name}/fetch`,
  async () => fetch('/subscriptions'),
);

export const createOrgSubscription = createAsyncThunk(
  `${name}/createOrgSubscription`,
  async ({ orgId, ...body }) =>
    post(`/organizations/${orgId}/subscriptions`, body),
);

export const useFetchStripeSubscriptions = () => {
  const dispatch = useDispatch();
  return () => dispatch(fetchStripeSubscriptions());
};

export const useCreateOrgSubscription = () => {
  const dispatch = useDispatch();
  return (args) => dispatch(createOrgSubscription(args));
};
