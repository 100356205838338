import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

import { fetchUser } from '../Users/thunks';
import { name } from './schema';

const organizationAdapter = createEntityAdapter();

const setIsLoading = (state) => {
  state.isLoading = true;
};

const setError = (state, action) => {
  state.error = action.error.message;
  state.isLoading = false;
};

const initialState = organizationAdapter.getInitialState({
  isLoading: false,
  error: null,
});

const addOrganizations = (state, action) => {
  const { organizations = {} } = action.payload.entities;
  if (!isEmpty(organizations)) {
    organizationAdapter.upsertMany(state, Object.values(organizations));
  }
  state.isLoading = false;
};

export const organizationsSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [fetchUser.pending]: setIsLoading,
    [fetchUser.fulfilled]: addOrganizations,
    [fetchUser.rejected]: setError,
  },
});

const selectOrganizations = ({ organizations }) => organizations;

const selectOrganizationsStatus = createSelector(
  selectOrganizations,
  ({ isLoading, error }) => ({ isLoading, error }),
);

export const selectors = {
  ...organizationAdapter.getSelectors(selectOrganizations),
  selectOrganizations,
  selectOrganizationsStatus,
};

export default organizationsSlice.reducer;
