import { Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import every from 'lodash/every';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

import { Row, TextInput } from '../../components';
import {
  useCreateUser,
  useGetVerificationCode,
} from '../../store/Users/thunks';
import { isValidEmail, isValidPhoneNumber } from '../../utils/validators';
import { SubmitButton, Subtitle } from './SignupComponents';

const Subtext = styled(Typography)`
  max-width: 400px;
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`;

const UserDetails = ({ form, goToNextStep, setError, onFormChanged }) => {
  const createUser = useCreateUser();
  const getVerificationCode = useGetVerificationCode();
  const [isLoading, setIsLoading] = useState(false);

  const { fullName, email, phoneNumber } = form;

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      await createUser({
        fullName,
        email,
        phoneNumber,
      });

      await getVerificationCode({ phoneNumber });

      goToNextStep();
    } catch {
      setError();
    } finally {
      setIsLoading(false);
    }
  };

  const isButtonDisabled = !every([
    isValidPhoneNumber(phoneNumber),
    isValidEmail(email),
    fullName.length,
    !isLoading,
  ]);

  return (
    <>
      <Subtitle>User Details</Subtitle>
      <TextInput
        label="Full Name"
        id="fullName"
        value={fullName}
        onChange={onFormChanged}
      />
      <TextInput
        label="Email"
        id="email"
        value={email}
        onChange={onFormChanged}
      />
      <NumberFormat
        id="phoneNumber"
        label="Phone Number"
        value={phoneNumber}
        onChange={onFormChanged}
        format="(###) ###-####"
        mask="_"
        customInput={TextInput}
      />
      <Subtext>
        Tapping “Next” will send you an SMS. Message and data rates may apply.
      </Subtext>
      <SubmitButton
        disabled={isButtonDisabled}
        isLoading={isLoading}
        onClick={handleSubmit}
      >
        Next
      </SubmitButton>
      <Row>
        <Link href="/login">Login</Link>
      </Row>
    </>
  );
};

UserDetails.propTypes = {
  form: PropTypes.object.isRequired,
  goToNextStep: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  onFormChanged: PropTypes.func.isRequired,
};

export default UserDetails;
