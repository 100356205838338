import {
  Container as BaseContainer,
  Hidden,
  List as BaseList,
} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

import { useActiveContext } from '../../hooks';
import { useGroups } from '../../store/Groups/selectors';
import BaseLogo, { LOGO_VARIANTS } from '../Logo';
import BurguerMenu from './Burguer/BurguerMenu';
import Link from './Link';
import NavBarLinks from './NavBarLinks';

const Container = styled(BaseContainer)`
  padding-top: ${({ theme: { spacing } }) => spacing(1)}px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding-top: ${({ theme: { spacing } }) => spacing(1)}px;
`;

const Logo = styled(BaseLogo).attrs(() => ({
  height: 40,
  width: 40,
}))``;

const List = styled(BaseList)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-end;
`;

const LeftSideWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.up('md')} {
    justify-content: flex-start;
  }
`;

const LogoWrapper = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme: { palette } }) => palette.text.primary};
`;

const NavBar = () => {
  const { activeGroup } = useActiveContext();
  const groups = useGroups();
  const { name: groupName = '' } = groups[activeGroup] || {};

  return (
    <Container maxWidth="md">
      <LeftSideWrapper>
        <Hidden mdUp>
          <BurguerMenu />
        </Hidden>
        <LogoWrapper href="/">
          <Logo variant={LOGO_VARIANTS.SMALL} />
          {['TotalTeam', groupName].filter(Boolean).join(' - ')}
        </LogoWrapper>
      </LeftSideWrapper>
      <Hidden smDown>
        <List component="nav" aria-labelledby="main navigation">
          <NavBarLinks />
        </List>
      </Hidden>
    </Container>
  );
};

export default NavBar;
