import { createAsyncThunk } from '@reduxjs/toolkit';
import find from 'lodash/find';
import { normalize } from 'normalizr';
import { useDispatch } from 'react-redux';

import { put } from '../../utils/api';
import { getBaseSurveyUrl } from '../../utils/transform';
import { name, surveyQuestionSchema } from './schema';

export const updateSurveyQuestion = createAsyncThunk(
  `${name}/update`,
  async ({ updates, token, questionId, surveyId, ...args }) => {
    const baseUrl = getBaseSurveyUrl(args);
    const { surveyQuestions } = await put(
      `${baseUrl}/${surveyId}/questions/${questionId}?token=${token}`,
      updates,
    );

    const question = find(surveyQuestions, { id: questionId });
    return normalize(question, surveyQuestionSchema);
  },
);

export const useUpdateSurveyQuestion = () => {
  const dispatch = useDispatch();
  return (args) => dispatch(updateSurveyQuestion(args));
};
