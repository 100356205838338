import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useSetToken, useToken } from '../../utils/hooks';
import CalendarView from './CalendarView';

function getJsonFromUrl(url) {
  if (!url) url = window.location.search;
  const query = url.substr(1);
  const result = {};
  query.split('&').forEach((part) => {
    const item = part.split('=');
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}

const CalendarIntermediate = () => {
  const location = useLocation();
  const token = useToken();
  const setToken = useSetToken();
  const [isFromApp, setIsFromApp] = useState(null);

  const params = useMemo(() => {
    return getJsonFromUrl(location.search || 'none');
  }, [location]);

  useEffect(() => {
    if (!params) {
      return;
    }
    const { token: urlToken, fromApp } = params;
    if (urlToken) {
      setToken(urlToken);
    }
    if (fromApp) {
      setIsFromApp(true);
    }
  }, [params, setToken]);

  if (!token) {
    return <div>You are not signed in</div>;
  }

  return <CalendarView fromApp={isFromApp} />;
};

export default CalendarIntermediate;
