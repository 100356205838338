import React, { useEffect } from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';

import { Container } from '../../components';
import { PARAMS, PATHS } from '../../constants';
import { useActiveContext } from '../../hooks';
import {
  useSurveyCursor,
  useSurveyQuestionsStatus,
} from '../../store/SurveyQuestions/selectors';
import {
  useDateTaken,
  useSurveyQuestionIdsForSurvey,
  useTodaysSurvey,
} from '../../store/Surveys/selectors';
import { useFetchSurvey } from '../../store/Surveys/thunks';
import { useTeamRosterForGroupUser } from '../../store/TeamRosters/selectors';
import { useCurrentUserId } from '../../utils/hooks';
import { toPath } from '../../utils/transform';
import Complete from './Complete';
import Review from './Review';
import Survey from './Survey';

const { SURVEY_STEP } = PARAMS;
const { COMPLETE, STEP, SURVEY } = PATHS;

export default () => {
  const {
    activeOrganization: orgId,
    activeGroup: groupId,
  } = useActiveContext();

  const userId = useCurrentUserId();
  const teamRosterId = useTeamRosterForGroupUser({ userId, groupId });

  const stepMatch = useRouteMatch(toPath(SURVEY, STEP, `:${SURVEY_STEP}`));

  const completeMatch = useRouteMatch(toPath(SURVEY, COMPLETE));

  const isCompleteRoute = completeMatch !== null;

  const fetchSurvey = useFetchSurvey();
  const todaysSurvey = useTodaysSurvey(teamRosterId);

  const { id: surveyId = null } = todaysSurvey || {};

  const dateTaken = useDateTaken(surveyId);
  const isComplete = dateTaken !== null;

  const cursor = useSurveyCursor(surveyId);
  const surveyQuestionIds = useSurveyQuestionIdsForSurvey(surveyId);
  const surveyQuestionsStatus = useSurveyQuestionsStatus();

  useEffect(() => {
    if (!teamRosterId) {
      return;
    }

    fetchSurvey({ orgId, groupId, teamRosterId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamRosterId, orgId, groupId]);

  if (!surveyId) {
    return null;
  }

  if (cursor === null || surveyQuestionIds === null) {
    return null;
  }

  if (!isCompleteRoute && isComplete) {
    return <Redirect to={toPath(SURVEY, COMPLETE)} />;
  }

  const pathToStep = (toStep) => toPath(SURVEY, STEP, toStep);

  const redirectToCursor = () => <Redirect to={pathToStep(cursor)} />;

  if (!isComplete && (isCompleteRoute || stepMatch === null)) {
    return redirectToCursor();
  }

  let content;
  if (isCompleteRoute && isComplete) {
    content = <Complete surveyId={surveyId} />;
  } else {
    const step = Number(stepMatch.params.surveyStep);
    if (step > cursor && !surveyQuestionsStatus.isLoading) {
      return redirectToCursor();
    }

    if (step === surveyQuestionIds.length) {
      content = (
        <Review surveyId={surveyId} step={step} pathToStep={pathToStep} />
      );
    } else {
      content = (
        <Survey surveyId={surveyId} step={step} pathToStep={pathToStep} />
      );
    }
  }

  return <Container>{content}</Container>;
};
