import jwtDecode from 'jwt-decode';
import { useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { QUERY } from '../constants';

const { TOKEN } = QUERY;

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useParam(key) {
  const params = useParams();
  const param = params[key];
  if (param == null) {
    return null;
  }
  return Number(param);
}

export function useQuery() {
  const location = useLocation();
  return new URLSearchParams(location.search);
}

export function useQueryToken() {
  const query = useQuery();
  return query.get(TOKEN);
}

export function useToken() {
  return window.localStorage.getItem(TOKEN);
}

export function setToken(token) {
  window.localStorage.setItem(TOKEN, token);
}

export function useSetToken() {
  return (token) => setToken(token);
}

export function useClearToken() {
  return () => window.localStorage.removeItem(TOKEN);
}

export function useCurrentUserId() {
  const token = useToken();
  if (token == null) {
    return null;
  }

  const { userId } = jwtDecode(token);
  return userId;
}
