import Card from '@material-ui/core/Card';
import styled from 'styled-components';

const StyledCard = styled(Card)`
  padding: 92px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 16px;
  }
  margin: 16px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 640px;
  background-color: ${({
    theme: {
      palette: {
        background: { transparent },
      },
    },
  }) => transparent};
`;

export default StyledCard;
