import { useSelector } from 'react-redux';

import { selectors } from './slice';

const {
  selectTotal,
  selectEntities,
  selectOrganizationsStatus,
  selectIds,
} = selectors;

export const useOrganizationIds = () => useSelector(selectIds);
export const useOrganizations = () => useSelector(selectEntities);
export const useOrganizationsTotal = () => useSelector(selectTotal);
export const useOrganizationsStatus = () =>
  useSelector(selectOrganizationsStatus);

export const useOrderedOrganizations = () => {
  const ids = useOrganizationIds();
  const organizations = useOrganizations();
  return ids.map((id) => organizations[id]);
};
