import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import merge from 'lodash/merge';

// Tool for naming colors: https://chir.ag/projects/name-that-color

const gray = '#C4C4C4';
const white = '#FFFFFF';
const orange = '#F29100';
const toryBlue = '#0F558F';
const anakiwa = '#91CEFF';
const transparent = 'rgba(0,0,0,0.2)';

export const getTheme = (themeValues = {}) => {
  const theme = createMuiTheme();
  const {
    primaryColor = toryBlue,
    secondaryColor = anakiwa,
    actionColor = orange,
    textColor = white,
  } = themeValues;

  return merge(theme, {
    palette: {
      background: {
        default: primaryColor,
        transparent,
      },
      text: {
        primary: textColor,
        secondary: secondaryColor,
      },
      primary: {
        main: actionColor,
        light: actionColor,
        dark: actionColor,
      },
      secondary: {
        main: primaryColor,
        light: secondaryColor,
        dark: primaryColor,
      },
      asymptomatic: theme.palette.success.main,
      atRisk: theme.palette.warning.main,
      symptomatic: theme.palette.error.main,
    },
    shape: {
      borderRadius: 0,
    },
    typography: {
      htmlFontSize: 14,
      color: textColor,
      button: {
        textTransform: 'none',
        fontSize: '1rem',
      },
      question: {
        color: textColor,
      },
      h5: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
    },
    props: {
      MuiLink: {},
      MuiButton: {
        color: 'primary',
        disableElevation: true,
        variant: 'contained',
      },
    },
    overrides: {
      MuiPaper: {
        root: {
          minWidth: '80%',
          maxWidth: 960,
        },
      },
      MuiButton: {
        contained: {
          color: 'textPrimary',
          padding: theme.spacing(1),
          '&.Mui-disabled': {
            color: textColor,
            backgroundColor: gray,
          },
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          color: primaryColor,
        },
      },
      MuiPickersYear: { root: { color: primaryColor } },
      MuiPickersDay: { day: { color: primaryColor } },
      MuiFormLabel: {
        root: {
          color: 'rgba(255, 255, 255, 0.3)',
        },
      },
      MuiInput: {
        underline: {
          '&:before': {
            borderBottomColor: actionColor,
          },
        },
      },
      MuiDivider: {
        root: {
          borderTop: 'solid 1px',
          borderTopColor: gray,
          marginTop: '50px',
          width: '100%',
        },
      },
      MuiInputBase: {
        input: {
          color: actionColor,
          '&:-webkit-autofill': {
            WebkitBoxShadow: `0 0 0 100px ${primaryColor} inset`,
            WebkitTextFillColor: textColor,
          },
        },
      },
      MuiLink: {
        root: {
          fontWeight: 'bold',
          color: secondaryColor,
        },
        button: {
          fontSize: '1rem',
        },
        underlineAlways: {
          borderBottom: 'inset',
          textDecoration: 'none',
          color: textColor,
          borderBottomColor: actionColor,
          borderBottomWidth: theme.spacing(0.5),
          marginBottom: -theme.spacing(0.5),
        },
      },
      MuiSnackbar: {
        anchorOriginTopCenter: {
          [theme.breakpoints.up(0)]: {
            top: 0,
          },
        },
        root: {
          display: 'block',
          width: '100%',
        },
      },
      MuiSnackbarContent: {
        root: {
          backgroundColor: primaryColor,
          fontSize: '1rem',
          justifyContent: 'center',
        },
      },
      MuiCircularProgress: {
        colorPrimary: {
          color: textColor,
        },
      },
      MuiLinearProgress: {
        colorPrimary: {
          backgroundColor: gray,
        },
        barColorPrimary: {
          backgroundColor: secondaryColor,
        },
      },
      MuiFormControl: {
        root: {
          width: '100%',
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2),
        },
      },
      MuiMenu: {
        list: {
          backgroundColor: primaryColor,
        },
      },
      MuiSelect: {
        icon: {
          color: textColor,
        },
      },
      MuiInputLabel: {
        root: {
          '&.Mui-disabled': {
            color: gray,
          },
        },
      },
      MuiSvgIcon: {
        root: {
          fill: textColor,
        },
      },
    },
    survey: {
      cardSpacing: theme.spacing(4),
      itemSpacing: theme.spacing(3),
    },
  });
};
