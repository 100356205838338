import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';

import { fetch } from '../utils/api';

export const ConstantsContext = createContext();

const ConstantsContextProvider = ({ children }) => {
  const [constants, setConstants] = useState(null);

  useEffect(() => {
    (async () => {
      const response = await fetch('/assets/constants');
      setConstants(response);
    })();
  }, []);

  return (
    <ConstantsContext.Provider value={{ constants }}>
      {children}
    </ConstantsContext.Provider>
  );
};

ConstantsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ConstantsContextProvider;
