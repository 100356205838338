import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import CreateIcon from '@material-ui/icons/Create';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { DockingButton, Paper, SurveyCard } from '../../components';
import { PATHS } from '../../constants';
import { useActiveContext } from '../../hooks';
import { useSurveyQuestionsForSurvey } from '../../store/SurveyQuestions/selectors';
import { useCompleteSurvey } from '../../store/Surveys/thunks';
import { useTeamRosterForGroupUser } from '../../store/TeamRosters/selectors';
import { useCurrentUserId } from '../../utils/hooks';
import { toPath } from '../../utils/transform';
import Progress from './Progress';

const { COMPLETE, SURVEY } = PATHS;

const Title = styled(Typography)`
  margin-bottom ${({ theme: { spacing } }) => spacing(3)}px;
`;

const ReviewList = styled(List)`
  margin: 0;
  padding: 0;
`;

const ReviewItem = styled(ListItem)`
  display: list-item;
  margin-bottom: ${({ theme }) => theme.survey.itemSpacing}px;
  padding: 0;
  font-size: 1rem;
`;

const ReviewQuestion = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  display: inline-block;
`;

const ReviewAnswer = styled(Typography)`
  cursor: pointer;
  &:hover: {
    text-decoration: underline;
  }
`;

const ReviewButton = styled(IconButton)`
  float: left;
  margin-top: -9px;
  margin-left: -9px;
  padding: 9px;
`;

const EditIcon = styled(CreateIcon)`
  font-size: 1.25rem;
`;

const AnswerList = styled.ul`
  list-style: none;
  padding-left: ${({ theme }) => theme.spacing(4)}px;
`;

const Attestation = styled(FormControlLabel)`
  margin: 0;
  margin-bottom: ${({ theme }) => theme.survey.itemSpacing}px;
  align-items: flex-start;
  text-align: left;
`;

const AttestationCheckbox = styled(Checkbox)`
  float: left;
  margin-top: -9px;
  margin-left: -9px;
  padding: 9px;
`;

const Question = ({ question, answers, possibleAnswers, handleEdit }) => (
  <ReviewItem>
    <ReviewQuestion component="div">{question}</ReviewQuestion>
    <ReviewAnswer component="div" color="primary" onClick={handleEdit}>
      <ReviewButton color="primary">
        <EditIcon />
      </ReviewButton>
      <AnswerList>
        {answers.map(({ surveyAnswerId }) => (
          <li key={surveyAnswerId}>
            {possibleAnswers[surveyAnswerId].displayName}
          </li>
        ))}
      </AnswerList>
    </ReviewAnswer>
  </ReviewItem>
);

Question.propTypes = {
  question: PropTypes.string.isRequired,
  answers: PropTypes.array.isRequired,
  possibleAnswers: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
};

const Review = ({ surveyId, step, pathToStep }) => {
  const history = useHistory();
  const {
    activeOrganization: orgId,
    activeGroup: groupId,
  } = useActiveContext();
  const userId = useCurrentUserId();
  const teamRosterId = useTeamRosterForGroupUser({ userId, groupId });

  const questions = useSurveyQuestionsForSurvey(surveyId);
  const completeSurvey = useCompleteSurvey();
  const [approved, setApproved] = useState(false);

  const handleApprove = () => setApproved(!approved);
  const handleSubmit = () => {
    completeSurvey({ orgId, groupId, teamRosterId, surveyId });
    history.push(`${toPath(SURVEY, COMPLETE)}`);
  };

  return (
    <Paper square>
      <SurveyCard>
        <Title variant="h5">Survey Review</Title>
        <Progress surveyId={surveyId} step={step} />
        <ReviewList component="ol">
          {questions.map(
            ({ id, question, answers, possibleAnswers }, questionStep) => {
              const handleEdit = () => history.push(pathToStep(questionStep));

              return (
                <Question
                  key={id}
                  question={question}
                  answers={answers}
                  possibleAnswers={possibleAnswers}
                  handleEdit={handleEdit}
                />
              );
            },
          )}
        </ReviewList>
        <Attestation
          control={<AttestationCheckbox />}
          label="I hereby certify that all answers to the survey are true and represent my current conditions accurately to the best of my ability."
          checked={approved}
          onChange={handleApprove}
        />
        <DockingButton disabled={!approved} onClick={handleSubmit}>
          Submit
        </DockingButton>
      </SurveyCard>
    </Paper>
  );
};

Review.propTypes = {
  surveyId: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  pathToStep: PropTypes.func.isRequired,
};

export default Review;
