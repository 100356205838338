import { Typography } from '@material-ui/core';
import styled from 'styled-components';

import { DockingButton } from '../../components';

export const Title = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(4)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

export const SubmitButton = styled(DockingButton)`
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`;

export const Subtitle = styled(Typography).attrs(() => ({ variant: 'h6' }))`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

export const Subtext = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`;

export const SignupForm = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  height: 630px;
`;
