/* eslint jsx-a11y/anchor-is-valid: 0 */
import { ListItem, ListItemText } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { PATHS } from '../constants';
import { useClearToken } from '../utils/hooks';
import { toPath } from '../utils/transform';
import Link from './NavBar/Link';

const { LOGIN } = PATHS;
const LogOutButton = () => {
  const clearToken = useClearToken();
  const history = useHistory();

  const onClickSignout = () => {
    clearToken();
    history.push(toPath(LOGIN));
  };

  return (
    <Link component="button" onClick={onClickSignout}>
      <ListItem button>
        <ListItemText primary="Log out" />
      </ListItem>
    </Link>
  );
};

export default LogOutButton;
