import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

const SubscriptionDetails = styled(Typography)`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  width: 400px;
`;

const SubscriptionItem = ({ name, price }) => {
  return (
    <SubscriptionDetails variant="h6">
      {name}
      <NumberFormat
        value={price / 100}
        displayType="text"
        thousandSeparator
        prefix="$"
      />
    </SubscriptionDetails>
  );
};

SubscriptionItem.propTypes = {
  name: PropTypes.string,
  price: PropTypes.number,
};

SubscriptionItem.defaultProps = {
  name: null,
  price: null,
};

export default SubscriptionItem;
