import { useEffect, useRef, useState } from 'react';

export const useLocalStorageState = (
  key,
  defaultValue = '',
  { serialize = JSON.stringify, deserialize = JSON.parse } = {},
) => {
  const [state, setState] = useState(() =>
    typeof defaultValue === 'function' ? defaultValue() : defaultValue,
  );
  const prevKeyRef = useRef(key);

  const setInitialValue = async () => {
    const valueInLocalStorage = await window.localStorage.getItem(key);
    if (valueInLocalStorage) {
      try {
        setState(deserialize(valueInLocalStorage));
      } catch (error) {
        window.localStorage.removeItem(key);
      }
    }
  };

  useEffect(() => {
    setInitialValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const prevKey = prevKeyRef.current;
    if (prevKey !== key) {
      window.localStorage.removeItem(prevKey);
    }
    prevKeyRef.current = key;
    window.localStorage.setItem(key, serialize(state));
  }, [key, state, serialize]);

  return [state, setState];
};
