import { isNil } from 'lodash';
import find from 'lodash/find';
import head from 'lodash/head';
import PropTypes from 'prop-types';
import React, { createContext, useCallback, useEffect, useState } from 'react';

import { useActiveTheme } from '../hooks/useActiveTheme';
import { useLocalStorageState } from '../hooks/useLocalStorageState';
import {
  useGroups,
  useGroupsStatus,
  useGroupsTotal,
} from '../store/Groups/selectors';
import {
  useOrganizations,
  useOrganizationsStatus,
  useOrganizationsTotal,
} from '../store/Organizations/selectors';

export const ActiveContext = createContext();

const STORAGE_NAME = 'activeContext';

const ActiveContextProvider = ({ children }) => {
  const organizations = useOrganizations();
  const organizationsTotal = useOrganizationsTotal();
  const groups = useGroups();
  const groupsTotal = useGroupsTotal();

  const { setActiveTheme } = useActiveTheme();
  const [activeState, setActiveState] = useLocalStorageState(STORAGE_NAME, {
    activeOrganization: null,
    activeGroup: null,
  });
  const [isContextLoading, setIsContextLoading] = useState(false);

  const { isLoading: isOrgLoading } = useOrganizationsStatus();
  const { isLoading: isGroupLoading } = useGroupsStatus();

  const setActive = (newState) => {
    const updatedState = { ...activeState, ...newState };
    setActiveState(updatedState);
  };

  const setDefaultValues = useCallback(async () => {
    setIsContextLoading(true);
    const {
      activeOrganization: storedActiveOrg,
      activeGroup: storedActiveGroup,
    } = activeState;

    const validStoredIDs = [
      storedActiveOrg,
      storedActiveGroup,
      organizations[storedActiveOrg],
      groups[storedActiveGroup],
    ].every(Boolean);

    const activeGroupBelongsToOrg =
      groups[storedActiveGroup]?.orgId === storedActiveOrg;
    if (validStoredIDs && activeGroupBelongsToOrg) {
      setActiveState({
        activeOrganization: storedActiveOrg,
        activeGroup: storedActiveGroup,
      });
    } else {
      // If it's done checking on local storage, set first org and group as defaults
      let defaultOrg = find(
        organizations,
        ({ groups: orgGroups = [] }) => orgGroups.length,
      );
      if (!defaultOrg) {
        defaultOrg = head(Object.values(organizations));
      }

      const [defaultGroup] = defaultOrg?.groups || [];
      if (defaultOrg && defaultGroup) {
        setActive({
          activeOrganization: defaultOrg?.id,
          activeGroup: defaultGroup,
        });
      }
    }

    setIsContextLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeState, groups, organizations]);

  useEffect(() => {
    if (!activeState.activeOrganization || !organizations) return;
    const { theme } = organizations[activeState.activeOrganization] || {};
    setActiveTheme(theme);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeState?.activeOrganization]);

  useEffect(() => {
    const alreadyHasValues = [
      activeState.activeOrganization,
      activeState.activeGroup,
      organizations[activeState.activeOrganization],
      groups[activeState.activeGroup],
    ].every(Boolean);

    const organizationGotDeleted = !organizations[
      activeState.activeOrganization
    ];
    const noActiveOrganizationSelected =
      organizationsTotal && !alreadyHasValues;
    const noActiveData = organizationGotDeleted || noActiveOrganizationSelected;
    const isStoreLoading = [isOrgLoading, isGroupLoading].some(Boolean);

    if (!isStoreLoading && noActiveData && !isNil(organizationsTotal)) {
      setDefaultValues();
    }
  }, [
    isOrgLoading,
    organizations,
    organizationsTotal,
    groups,
    groupsTotal,
    isGroupLoading,
    activeState,
    setDefaultValues,
  ]);

  return (
    <ActiveContext.Provider
      value={{
        isLoading: isContextLoading,
        setActive,
        ...activeState,
      }}
    >
      {children}
    </ActiveContext.Provider>
  );
};

ActiveContextProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export default ActiveContextProvider;
