/* eslint-disable react/prop-types */
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import { DockingButton } from '../../components';
import arrowBack from '../../images/arrow-back.png';
import close from '../../images/close.png';
import info from '../../images/info.png';
import profile1 from '../../images/profile-pic-1.png';
import profile2 from '../../images/profile-pic-2.png';
import statusBar from '../../images/status-bar.png';
import { getTheme } from '../../theme';

const STAFF = [
  { name: 'Andrew Pearson', imageUrl: profile1 },
  { name: 'Andrew Pearson', imageUrl: profile2 },
  { name: 'Andrew Pearson', imageUrl: profile2 },
  { name: 'Andrew Pearson', imageUrl: profile2 },
  { name: 'Andrew Pearson', imageUrl: profile2 },
];

const PhoneContainer = styled.div`
  background-color: ${({ theme: { palette } }) => palette.background.default};
  width: 320px;
  height: 568px;
  position: relative;
`;

const Button = styled(DockingButton)`
  position: absolute;
  bottom: 0;
`;

const StatusBar = styled.img.attrs(() => ({
  src: statusBar,
}))``;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin: 10px;
  padding: 10px;
  justify-content: space-between;
`;

const BackButton = styled.img.attrs(() => ({
  src: arrowBack,
}))`
  height: 24px;
`;

const NavigationTitle = styled.b`
  color: ${({ theme: { palette } }) => palette.text.primary};
`;

const InformationIcon = styled.img.attrs(() => ({
  src: info,
}))`
  height: 24px;
  width: 24px;
`;

const Navigation = ({ title }) => (
  <Row>
    <BackButton />
    <NavigationTitle>{title}</NavigationTitle>
    <InformationIcon />
  </Row>
);

const Avatar = styled.img`
  height: 48px;
  width: 48px;
  border-radius: 48px;
  margin-right: 10px;
`;

const Name = styled.span`
  color: ${({ theme: { palette } }) => palette.text.primary};
`;
const CloseIcon = styled.img.attrs(() => ({
  src: close,
}))`
  height: 24px;
  width: 24px;
`;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RowWithBorder = styled(Row)`
  border-bottom: ${({ theme: { palette } }) =>
    `1px solid ${palette.text.primary}`};
`;

const StaffRow = ({ staff: { name, imageUrl } }) => (
  <RowWithBorder>
    <AvatarContainer>
      <Avatar src={imageUrl} />
      <Name>{name}</Name>
    </AvatarContainer>
    <CloseIcon />
  </RowWithBorder>
);

const Preview = ({ themeValues }) => {
  const theme = getTheme(themeValues);
  return (
    <ThemeProvider theme={theme}>
      <MuiThemeProvider theme={theme}>
        <PhoneContainer>
          <StatusBar />
          <Navigation title="Manage Staff" />
          {STAFF.map((staff) => (
            <StaffRow staff={staff} />
          ))}
          <Button>INVITE STAFF</Button>
        </PhoneContainer>
      </MuiThemeProvider>
    </ThemeProvider>
  );
};

export default Preview;
