import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const SurveyOptionContainer = styled(Button)`
  && {
    background-color: ${({ theme, selected }) =>
      selected ? theme.palette.secondary.main : 'transparent'};
    border: 1px solid ${({ theme }) => theme.palette.secondary.main};
    height: 30px;
    width: ${({ theme }) => theme.survey.cardWidth};
    border-radius: 8px;
    margin-bottom: 10px;
  }
`;

const SurveyOption = ({ label, selected, onPress }) => (
  <SurveyOptionContainer selected={selected} onClick={onPress}>
    {label}
  </SurveyOptionContainer>
);

SurveyOption.propTypes = {
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onPress: PropTypes.func.isRequired,
};

export default SurveyOption;
