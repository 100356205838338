import styled from 'styled-components';

import BaseButton from './BaseButton';

const DockingButton = styled(BaseButton)`
  width: 100%;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    position: absolute;
    left: 0;
    bottom: 0;
  }
`;

export default DockingButton;
