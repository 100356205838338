import LinearProgress from '@material-ui/core/LinearProgress';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { useSurveyQuestionIdsForSurvey } from '../../store/Surveys/selectors';

export const SurveyProgress = styled(LinearProgress)`
  height: 6px;
  margin-top: ${({ theme }) => theme.survey.cardSpacing / 2}px;
  margin-bottom: ${({ theme }) => theme.survey.cardSpacing / 2}px;
  width: 100%;
`;

const Progress = ({ surveyId, step }) => {
  const questionIds = useSurveyQuestionIdsForSurvey(surveyId);
  const totalSteps = questionIds.length;
  const progress = Math.floor(((step + 1) / (totalSteps + 2)) * 100);

  return <SurveyProgress variant="determinate" value={progress} />;
};

Progress.propTypes = {
  surveyId: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
};

export default Progress;
