import filter from 'lodash/filter';
import { useSelector } from 'react-redux';

import { selectors } from './slice';

const { selectEntities, selectGroupsStatus, selectTotal } = selectors;

export const useGroups = () => useSelector(selectEntities);
export const useGroupsStatus = () => useSelector(selectGroupsStatus);
export const useGroupsTotal = () => useSelector(selectTotal);

export const useGroupsForOrg = (orgId) => {
  const groups = useGroups();
  return filter(groups, { orgId });
};
