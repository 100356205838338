import moment from 'moment';
import { useSelector } from 'react-redux';

import { selectors } from './slice';

const {
  selectEntities,
  selectById,
  selectSurveysStatus,
  selectIds,
} = selectors;

export const useSurveys = () => useSelector(selectEntities);
export const useSurveyIds = () => useSelector(selectIds);
export const useSurveyById = (id) =>
  useSelector((state) => selectById(state, id));
export const useSurveysStatus = () => useSelector(selectSurveysStatus);

export const useTodaysSurvey = (teamRosterId) => {
  const surveys = useSelector(selectEntities);
  const surveyMap = Object.values(surveys);
  const today = moment().utc();
  return surveyMap.find((survey) => {
    const surveyDate = moment(survey.dateRequired).utc();
    const isSameTeamRoster = teamRosterId === survey.teamRosterId;
    const isForToday = surveyDate.isSame(today, 'd');
    return [isForToday, isSameTeamRoster].every(Boolean);
  });
};

export const useSurveyQuestionIdsForSurvey = (id) => {
  const survey = useSurveyById(id);
  if (survey == null) {
    return null;
  }
  return survey.surveyQuestions;
};

export const useDateTaken = (surveyId) => {
  const survey = useSurveyById(surveyId);
  if (survey == null) {
    return null;
  }
  return survey.dateTaken;
};

export const useDateRequired = (surveyId) => {
  const survey = useSurveyById(surveyId);
  if (survey == null) {
    return null;
  }
  return survey.dateRequired;
};

export const useSurveyPolicies = (surveyId) => {
  const survey = useSurveyById(surveyId);
  if (survey == null) {
    return null;
  }
  return survey.policies;
};
