import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { PATHS } from '../constants';
import { useFetchUser } from '../store/Users/thunks';
import { useCurrentUserId } from '../utils/hooks';
import NavBar from './NavBar/NavBar';

const { LOGIN } = PATHS;

const ProtectedRoute = ({ component: Component }) => {
  const userId = useCurrentUserId();
  const fetchUser = useFetchUser();

  useEffect(() => {
    if (userId != null) {
      fetchUser(userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return userId == null ? (
    <Redirect to={{ pathname: `/${LOGIN}` }} />
  ) : (
    <>
      <NavBar />
      <Component />
    </>
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default ProtectedRoute;
