import React from 'react';
import { Redirect } from 'react-router-dom';

import { PATHS } from '../../constants';
import { useNavLinks } from '../../hooks';

const { ORGANIZATIONS, SURVEY } = PATHS;

// Redirecting to Subscriptions/Survey until we get a home page
const Home = () => {
  const links = useNavLinks();
  const linkNames = links.map(({ path }) => path);
  let pathname = `/${SURVEY}`;
  if (linkNames.includes(`/${ORGANIZATIONS}`)) {
    pathname = `/${ORGANIZATIONS}`;
  }

  return <Redirect to={{ pathname }} />;
};

export default Home;
