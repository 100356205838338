import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { useDispatch } from 'react-redux';

import { fetch, put } from '../../utils/api';
import { getBaseSurveyUrl } from '../../utils/transform';
import { name, surveySchema } from './schema';

export const fetchSurvey = createAsyncThunk(`${name}/fetch`, async (args) => {
  const baseUrl = getBaseSurveyUrl(args);
  const route = `${baseUrl}/date/today`;
  const survey = await fetch(route, { date: new Date() });
  return normalize(survey, surveySchema);
});

export const completeSurvey = createAsyncThunk(
  `${name}/complete`,
  async ({ surveyId, ...args }) => {
    const baseUrl = getBaseSurveyUrl(args);
    const survey = await put(`${baseUrl}/${surveyId}`, {
      completed: true,
    });
    return normalize(survey, surveySchema);
  },
);

export const useFetchSurvey = () => {
  const dispatch = useDispatch();
  return (args) => dispatch(fetchSurvey(args));
};

export const useCompleteSurvey = () => {
  const dispatch = useDispatch();
  return (args) => dispatch(completeSurvey(args));
};
