import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

import { name } from './schema';
import { fetchUser } from './thunks';

const userAdapter = createEntityAdapter();

const setIsLoading = (state) => {
  state.isLoading = true;
};

const setError = (state, action) => {
  state.isLoading = false;
  state.error = action.error.message;
};

const initialState = userAdapter.getInitialState({
  isLoading: false,
  error: null,
});

const addUsers = (state, action) => {
  state.isLoading = false;
  const { users = {} } = action.payload.entities;
  if (!isEmpty(users)) {
    userAdapter.upsertMany(state, Object.values(users));
  }
};

export const usersSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [fetchUser.pending]: setIsLoading,
    [fetchUser.fulfilled]: addUsers,
    [fetchUser.rejected]: setError,
  },
});

const selectUsers = ({ users }) => users;

const selectUsersStatus = createSelector(
  selectUsers,
  ({ isLoading, error }) => ({ isLoading, error }),
);

export const selectors = {
  ...userAdapter.getSelectors(selectUsers),
  selectUsers,
  selectUsersStatus,
};

export default usersSlice.reducer;
