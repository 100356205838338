import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { useDispatch } from 'react-redux';

import { del, fetch, patch, post } from '../../utils/api';
import { eventsCollectionSchema, name } from './schema';

export const fetchEvents = createAsyncThunk(
  `${name}/fetch`,
  async ({ orgId, groupId, startDate, endDate }) => {
    const { items: events } = await fetch(
      `/organizations/${orgId}/groups/${groupId}/calendar/events?start=${startDate}&end=${endDate}`,
    );
    return normalize(events, eventsCollectionSchema);
  },
);

export const createEvent = createAsyncThunk(
  `${name}/create`,
  ({ orgId, groupId, ...args }) =>
    post(`/organizations/${orgId}/groups/${groupId}/calendar/events`, args),
);

export const updateEvent = createAsyncThunk(
  `${name}/update`,
  ({ orgId, groupId, eventId, ...args }) =>
    patch(
      `/organizations/${orgId}/groups/${groupId}/calendar/events/${eventId}`,
      args,
    ),
);

export const removeEvent = createAsyncThunk(
  `${name}/remove`,
  ({ orgId, groupId, eventId }) =>
    del(`/organizations/${orgId}/groups/${groupId}/calendar/events/${eventId}`),
);

export const useFetchEvents = () => {
  const dispatch = useDispatch();
  return (args) => dispatch(fetchEvents(args));
};

export const useCreateEvent = () => {
  const dispatch = useDispatch();
  return (args) => dispatch(createEvent(args));
};

export const useUpdateEvent = () => {
  const dispatch = useDispatch();
  return (args) => dispatch(updateEvent(args));
};

export const useRemoveEvent = () => {
  const dispatch = useDispatch();
  return (args) => dispatch(removeEvent(args));
};
