/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link as BaseLink } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Link = styled(BaseLink)`
  color: ${({
    theme: {
      palette: { primary },
    },
  }) => primary.main};
  font-weight: normal;
  margin-bottom: ${({ theme: { spacing } }) => spacing(2)}px;
  div {
    padding: 0;
  }
  :hover {
    text-decoration: none;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    position: absolute;
    bottom: 50px;
    left: 0;
    margin-left: ${({ theme: { spacing } }) => spacing(2)}px;
  }
`;

const BackButton = ({ onClick }) => (
  <Link component="button" onClick={onClick}>
    Back
  </Link>
);

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default BackButton;
