import React, { createContext, useCallback } from 'react';

import { useLocalStorageState } from '../hooks/useLocalStorageState';

export const ActiveThemeContext = createContext();
ActiveThemeContext.displayName = 'ActiveThemeContext';

const STORAGE_NAME = 'activeTheme';

const ActiveThemeProvider = (props) => {
  const [activeTheme, setActiveThemeLocal] = useLocalStorageState(STORAGE_NAME);
  const setActiveTheme = useCallback(
    (newTheme) => {
      if (newTheme?.id !== activeTheme?.id) {
        setActiveThemeLocal(newTheme);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setActiveThemeLocal],
  );

  return (
    <ActiveThemeContext.Provider
      value={{ activeTheme, setActiveTheme }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default ActiveThemeProvider;
