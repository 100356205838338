import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import isValid from 'date-fns/isValid';
import jwtDecode from 'jwt-decode';
import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

import { ALERT_SEVERITY } from '../constants/index';
import { AlertContext } from '../contexts';
import { fetch, initializeAxios, post } from '../utils/api';
import { useClearToken, useSetToken } from '../utils/hooks';
import { isValidEmail, isValidPhoneNumber } from '../utils/validators';
import BaseAction from './Action';
import ConfirmationModal from './ConfirmationModal';
import DynamicFields from './DynamicFields';
import GuardianForm, { emptyGuardian } from './GuardianForm';
import Header from './Header';
import {
  DatePicker,
  Form,
  ImageUploader,
  SubmitButton,
  TextInput,
} from './inputs';

const JoinComponent = styled(Container)`
  padding: 16px;
`;

const MAX_GUARDIANS = 3;

const initialGuardiansState = [emptyGuardian];

const initialFormState = {
  firstName: '',
  lastName: '',
  email: '',
  dob: null,
};

const GuardiansHeader = styled(Typography)`
  margin-top: 40px;
  font-weight: 700;
`;

const Action = styled(BaseAction)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

const RemoveGuardianActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const SuccessMessage = () => (
  <>
    <Header />
    <Typography variant="h2" align="center">
      Thanks!
    </Typography>
  </>
);

function Join({ location }) {
  const [form, setForm] = useState(initialFormState);
  const [guardians, setGuardians] = useState(initialGuardiansState);
  const [guardianIdxForDeletion, setGuardianIdxForDeletion] = useState(null);

  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');

  const [customLabels, setCustomLabels] = useState([]);
  const [customLabelValues, setCustomLabelValue] = useState({});

  const [title, setTitle] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);

  const { showModal } = useContext(AlertContext);

  const { token } = Object.fromEntries(new URLSearchParams(location.search));

  const { inviteCodeId, orgId, groupId } = jwtDecode(token);

  const showErrorModal = useCallback(
    () =>
      showModal({
        message: 'Oops! Something went wrong',
        severity: ALERT_SEVERITY.ERROR,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const setToken = useSetToken();
  const clearToken = useClearToken();

  const lastGuardianFormRef = useRef();

  const getTitle = (groupName) => `
    ${groupName} is requesting the following information. Please fill out the following fields.
  `;

  const fetchCustomLabels = useCallback(async () => {
    try {
      const { labels = [] } = await fetch(
        `/organizations/${orgId}/groups/${groupId}/labels`,
      );
      setCustomLabels(labels);
      const { inviteCode } = await fetch(
        `/organizations/${orgId}/groups/${groupId}/invite-codes/${inviteCodeId}`,
      );
      setTitle(getTitle(inviteCode.Group.name));
    } catch (e) {
      showErrorModal();
      setCustomLabels([]);
    } finally {
      setIsLoading(false);
    }
  }, [showErrorModal, setCustomLabels, inviteCodeId, orgId, groupId]);

  useEffect(() => {
    fetchCustomLabels();
  }, [fetchCustomLabels]);

  const clearFields = () => {
    setForm(initialFormState);
    setCustomLabelValue({});
    setGuardians(initialGuardiansState);
    setFile(null);
    setFileName('');
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      setToken(token);
      initializeAxios(token);
      let profileImage;
      const { firstName, lastName, email, dob } = form;

      if (file) {
        const data = new FormData();
        data.append('image', file);
        const { imageUrl } = await post('/images/upload', data);
        profileImage = imageUrl;
      }

      await post(
        `/organizations/${orgId}/groups/${groupId}/invite-codes/${inviteCodeId}/player`,
        {
          fullName: `${firstName} ${lastName}`,
          email,
          dob,
          labels: customLabelValues,
          guardians,
          profileImage,
        },
      );
      clearFields();
      clearToken();
      setIsSuccessMessageVisible(true);
    } catch {
      showErrorModal();
    } finally {
      setIsLoading(false);
    }
  };

  const onCustomLabelValueChange = ({ id, value }) => {
    setCustomLabelValue({
      ...customLabelValues,
      [id]: value,
    });
  };

  const staticFieldsEmpty = Object.values(form).some(
    (field) => !field || !field.toString().length,
  );

  const dynamicFieldsEmpty = customLabels.some(
    ({ id }) => !customLabelValues[id],
  );

  // Ensure at least one guardian is fully completed
  const guardianFieldsEmpty = guardians.every((guardian) =>
    Object.values(guardian).some((value) => !value.length),
  );

  const invalidPhoneNumbers = guardians.some(
    (guardian) => !isValidPhoneNumber(guardian.phoneNumber),
  );

  const invalidEmails =
    !isValidEmail(form.email) ||
    guardians.some((guardian) => !isValidEmail(guardian.email));

  const invalidDateOfBirth = !isValid(form.dob);
  const isButtonDisabled = [
    staticFieldsEmpty,
    dynamicFieldsEmpty,
    guardianFieldsEmpty,
    isLoading,
    invalidPhoneNumbers,
    invalidEmails,
    invalidDateOfBirth,
  ].some(Boolean);

  const removeGuardian = (idx) => {
    if (guardians.length > 1) {
      setGuardians(guardians.filter((_, index) => index !== idx));
    }
    setGuardianIdxForDeletion(null);
  };

  const onGuardianRemove = (idx) => () => {
    const isEmpty = !Object.values(guardians[idx]).some(Boolean);
    if (isEmpty) {
      removeGuardian(idx);
    } else {
      setGuardianIdxForDeletion(idx);
    }
  };

  const renderGuardianForm = (guardian, index) => {
    const onGuardianValueChange = ({ field, value }) => {
      const updatedGuardian = { ...guardians[index], [field]: value };
      const guardiansCopy = [...guardians];
      guardiansCopy[index] = updatedGuardian;
      setGuardians(guardiansCopy);
    };

    const isLastForm = index === guardians.length - 1;
    const renderForm = () => (
      <>
        <GuardianForm
          key={`guardian-${index}`}
          guardian={guardian}
          index={index}
          onValueChange={onGuardianValueChange}
          ref={isLastForm ? lastGuardianFormRef : null}
        />
        {Boolean(guardians.length > 1) && (
          <RemoveGuardianActionContainer>
            <Action onClick={onGuardianRemove(index)}>Remove</Action>
          </RemoveGuardianActionContainer>
        )}
      </>
    );

    return index !== 0 ? (
      <>
        <Divider />
        {renderForm()}
      </>
    ) : (
      renderForm()
    );
  };

  const onFormInputValueChange = ({ target: { id, value } }) =>
    setForm({
      ...form,
      [id]: value,
    });

  const onAddGuardianClick = () => {
    if (guardians.length === MAX_GUARDIANS) return;
    setGuardians([...guardians, emptyGuardian]);
    setTimeout(() => {
      // eslint-disable-next-line no-unused-expressions
      lastGuardianFormRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 200);
  };

  const renderForm = () => {
    const { firstName, lastName, dob, email } = form;
    return (
      <>
        <Header subtext={title} />
        <TextInput
          label="First Name"
          id="firstName"
          value={firstName}
          onChange={onFormInputValueChange}
        />
        <TextInput
          label="Last Name"
          id="lastName"
          value={lastName}
          onChange={onFormInputValueChange}
        />
        <TextInput
          label="Email"
          id="email"
          value={email}
          onChange={onFormInputValueChange}
        />
        <DatePicker
          id="dob"
          value={dob}
          onChange={(value) => setForm({ ...form, dob: value })}
          label="Birthday"
        />

        <ImageUploader
          label="Profile Photo (optional)"
          fileName={fileName}
          id="contained-button-file"
          onChange={(e) => {
            setFile(e.target.files[0]);
            setFileName(e.target.files[0].name);
          }}
        />
        <DynamicFields
          fields={customLabels}
          values={customLabelValues}
          onValueChange={onCustomLabelValueChange}
        />

        <GuardiansHeader>Parents (Guardians)</GuardiansHeader>
        {guardians.map(renderGuardianForm)}
        {Boolean(guardians.length < MAX_GUARDIANS) && (
          <Action onClick={onAddGuardianClick}>Add</Action>
        )}
        <SubmitButton onClick={handleSubmit} isDisabled={isButtonDisabled}>
          Submit
        </SubmitButton>
      </>
    );
  };

  return (
    <JoinComponent maxWidth="sm">
      <Form isLoading={isLoading}>
        {isSuccessMessageVisible ? <SuccessMessage /> : renderForm()}
        {!isNull(guardianIdxForDeletion) && (
          <ConfirmationModal
            title="Remove Guardian"
            message={`Would you like to remove ${
              guardians[guardianIdxForDeletion]?.fullName || 'this guardians'
            }'s info?`}
            onConfirm={() => removeGuardian(guardianIdxForDeletion)}
            onCancel={() => setGuardianIdxForDeletion(null)}
          />
        )}
      </Form>
    </JoinComponent>
  );
}

Join.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Join;
