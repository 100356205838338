import React, { useCallback, useContext, useState } from 'react';

import { Container, Logo, Paper } from '../../components';
import { ALERT_SEVERITY } from '../../constants';
import { AlertContext } from '../../contexts';
import Organization from './Organization';
import Payment from './Payment';
import Progress from './Progress';
import { SignupForm, Title } from './SignupComponents';
import UserDetails from './UserDetails';
import Verification from './Verification';

const initialFormState = {
  orgId: null,
  orgName: '',
  fullName: '',
  phoneNumber: '',
  email: '',
  verificationCode: '',
};

const STEPS = {
  0: UserDetails,
  1: Verification,
  2: Organization,
  3: Payment,
};

const Signup = () => {
  const [form, setForm] = useState(initialFormState);
  const [step, setStep] = useState(0);

  const { showModal } = useContext(AlertContext);

  const showErrorModal = useCallback(
    (message) =>
      showModal({
        message: message ?? 'Oops! Something went wrong',
        severity: ALERT_SEVERITY.ERROR,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const showSuccessModal = () =>
    showModal({
      message: `Congratulation, ${form.orgName} has been created!`,
      severity: ALERT_SEVERITY.SUCCESS,
    });

  const onFormInputValueChange = ({ target: { id, value } }) => {
    return setForm({
      ...form,
      [id]: value,
    });
  };

  const CurrentStep = STEPS[step];

  return (
    <Container>
      <Paper>
        <SignupForm>
          <Logo height={75} />
          <Title variant="h5">Organization Signup</Title>
          <Progress step={step} totalSteps={Object.values(STEPS).length - 1} />
          <CurrentStep
            form={form}
            goToNextStep={() => setStep(step + 1)}
            showSuccessModal={showSuccessModal}
            setError={showErrorModal}
            onFormChanged={onFormInputValueChange}
          />
        </SignupForm>
      </Paper>
    </Container>
  );
};

export default Signup;
