import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { BackButton, DockingButton, Paper, SurveyCard } from '../../components';
import { ConstantsContext } from '../../contexts';
import { useActiveContext } from '../../hooks';
import { useSurveyStepData } from '../../store/SurveyQuestions/selectors';
import { useUpdateSurveyQuestion } from '../../store/SurveyQuestions/thunks';
import { useDateRequired } from '../../store/Surveys/selectors';
import { useTeamRosterForGroupUser } from '../../store/TeamRosters/selectors';
import { useCurrentUserId, usePrevious } from '../../utils/hooks';
import { getFormattedDate } from '../../utils/transform';
import CheckboxesInput from './CheckboxesInput';
import Progress from './Progress';
import RadiosInput from './RadiosInput';

const Subtitle = styled(Typography)`
  margin-top: -${({ theme: { spacing } }) => spacing(1)}px;
  margin-bottom ${({ theme: { spacing } }) => spacing(4)}px;
`;

const Question = styled(FormControl)`
  display: flex;
  align-items: center;
`;

const Prompt = styled(FormLabel)`
  ${({ theme }) => theme.typography.question}
  text-align: center;
  margin-top: ${({ theme: { spacing } }) => spacing(1)}px;
  margin-bottom ${({ theme: { spacing } }) => spacing(2)}px;
  width: 300px;
`;

const BackButtonContainer = styled.div`
  width: 100%;
  justify-content: flex-start;
  text-align: left;
`;

const Survey = ({ surveyId, step, pathToStep }) => {
  const history = useHistory();
  const {
    activeOrganization: orgId,
    activeGroup: groupId,
  } = useActiveContext();
  const userId = useCurrentUserId();
  const teamRosterId = useTeamRosterForGroupUser({ userId, groupId });

  const { constants } = useContext(ConstantsContext);
  const dateRequired = useDateRequired(surveyId);
  const formattedDate = getFormattedDate(dateRequired);

  const surveyStepData = useSurveyStepData(surveyId, step);
  const {
    questionId,
    question = '',
    answers,
    answerType,
    possibleAnswers = {},
  } = surveyStepData || {};

  const [selected, setSelected] = useState([]);
  const updateSurveyQuestion = useUpdateSurveyQuestion();
  const prevStep = usePrevious(step);

  useEffect(() => {
    if (step !== prevStep) {
      setSelected(answers);
    }
  }, [step, prevStep, answers]);

  const InputComponent = {
    [constants.surveyAnswerTypes.CHECKBOX]: CheckboxesInput,
    [constants.surveyAnswerTypes.RADIO]: RadiosInput,
  }[answerType];

  const handlePrevious = () => history.push(pathToStep(step - 1));
  const handleNext = () => {
    const update = selected.map((answerId) => ({
      answerId: Number(answerId),
      answer: possibleAnswers[answerId].answer,
    }));
    updateSurveyQuestion({
      orgId,
      groupId,
      teamRosterId,
      surveyId,
      questionId,
      updates: { answers: update },
    });
    history.push(pathToStep(step + 1));
  };

  const getBackButton = () => {
    if (step <= 0) {
      return null;
    }

    return (
      <BackButtonContainer>
        <BackButton onClick={handlePrevious} />
      </BackButtonContainer>
    );
  };

  if (!surveyStepData) return null;

  return (
    <Paper square>
      <SurveyCard>
        <Typography variant="h5">Daily Wellness Survey</Typography>
        <Subtitle variant="h6">{formattedDate}</Subtitle>
        <Progress surveyId={surveyId} step={step} />
        <Question>
          <Prompt>{question}</Prompt>
          <InputComponent
            options={Object.values(possibleAnswers)}
            selected={selected}
            setSelected={setSelected}
          />
        </Question>
        {getBackButton()}
        <DockingButton disabled={selected.length < 1} onClick={handleNext}>
          Continue
        </DockingButton>
      </SurveyCard>
    </Paper>
  );
};

Survey.propTypes = {
  surveyId: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  pathToStep: PropTypes.func.isRequired,
};

export default Survey;
