import { Divider, Typography } from '@material-ui/core';
import filter from 'lodash/filter';
import map from 'lodash/map';
import React from 'react';
import styled from 'styled-components';

import { Container, Paper as BasePaper } from '../../components';
import { useRequest } from '../../hooks';
import { useOrganizations } from '../../store/Organizations/selectors';
import { useCurrentUserId } from '../../utils/hooks';
import SubscriptionItem from './SubscriptionItem';

const Paper = styled(BasePaper)`
  display: flex;
  align-items: flex-start;
`;

const Organizations = () => {
  const activeOrganizations = useOrganizations();

  const userId = useCurrentUserId();
  const organizations = filter(activeOrganizations, ({ admin = [] }) =>
    admin.map(({ id }) => id).includes(userId),
  );

  const { data: inactiveOrganizations = {}, isLoading } = useRequest(
    '/organizations/inactive',
  );

  const { length: activeOrganizationsCount } = Object.keys(organizations);
  const { length: inactiveOrganizationsCount } = Object.keys(
    inactiveOrganizations,
  );

  const title =
    activeOrganizationsCount + inactiveOrganizationsCount === 1
      ? 'My Organization'
      : 'Organizations';

  return (
    <Container>
      <Paper square>
        <Typography variant="h5">{title}</Typography>
        {map(organizations, (org) => (
          <SubscriptionItem key={`active-org-${org.id}`} org={org} isActive />
        ))}
        {!isLoading && !!inactiveOrganizations.length && (
          <>
            {!!activeOrganizationsCount && <Divider />}
            {map(inactiveOrganizations, (org) => (
              <SubscriptionItem
                key={`expired-org-${org.id}`}
                org={org}
                isActive={false}
              />
            ))}
          </>
        )}
      </Paper>
    </Container>
  );
};

export default Organizations;
