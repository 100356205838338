import { createAsyncThunk } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { patch, post } from '../../utils/api';
import { name } from './schema';

export const createOrganization = createAsyncThunk(
  `${name}/createOrganization`,
  async ({ orgName }) => {
    const { org } = await post('/organizations', { name: orgName });
    return org;
  },
);

export const useCreateOrganization = () => {
  const dispatch = useDispatch();
  return (args) => dispatch(createOrganization(args));
};

export const updateOrganization = createAsyncThunk(
  `${name}/updateOrganization`,
  async ({ orgId, ...payload }) => {
    const { org } = await patch(`/organizations/${orgId}`, payload);
    return org;
  },
);

export const useUpdateOrganization = () => {
  const dispatch = useDispatch();
  return (args) => dispatch(updateOrganization(args));
};
