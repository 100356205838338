import { Link as BaseLink } from '@material-ui/core';
import styled from 'styled-components';

const Link = styled(BaseLink)`
  font-weight: normal;
  margin-left: ${({ theme: { spacing } }) => spacing(1)}px;
  margin-right: ${({ theme: { spacing } }) => spacing(1)}px;
  div {
    padding: 0;
  }
  :hover {
    text-decoration: none;
  }
`;
export default Link;
