import styled from 'styled-components';

const SurveyCard = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.up('md')} {
    width: 360px;
  }
  text-align: center;
`;

export default SurveyCard;
