import styled from 'styled-components';

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: start;
  background: ${({ theme: { palette } }) => palette.background.default};
  border-right: ${({ theme: { palette } }) =>
    `1px solid ${palette.secondary.main}`};
  transform: ${({ isOpen }) =>
    isOpen ? 'translateX(0)' : 'translateX(-100%)'};
  height: 100vh;
  text-align: left;
  padding: 3rem 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 1;

  ${({ theme: { breakpoints } }) => breakpoints.down('sm')} {
    width: 60%;
  }

  a,
  button {
    margin: 0;
    margin-bottom: 1rem;
    transition: color 0.3s linear;
  }
`;
