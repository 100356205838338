import { useSelector } from 'react-redux';

import { selectors } from './slice';

const { selectEntities, selectById, selectEventsStatus, selectIds } = selectors;

export const useEvents = () => useSelector(selectEntities);
export const useEventIds = () => useSelector(selectIds);
export const useEventById = (id) =>
  useSelector((state) => selectById(state, id));
export const useEventsStatus = () => useSelector(selectEventsStatus);
