import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import PropTypes from 'prop-types';
import React from 'react';

import SurveyOption from './SurveyOption';

const RadioInput = ({ key, value, label, selected, setSelected }) => {
  const handleChange = () => setSelected([value]);
  const checked = selected.includes(value);

  return (
    <SurveyOption
      key={key}
      label={label}
      selected={checked}
      onPress={handleChange}
    />
  );
};

RadioInput.propTypes = {
  key: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
};

const RadiosInput = ({ options, selected, setSelected }) => (
  <FormControl compoonent="fieldset">
    <RadioGroup value={selected[0] || null}>
      {options.map(({ id: answerId, displayName }) => (
        <RadioInput
          key={answerId}
          value={String(answerId)}
          label={displayName}
          selected={selected}
          setSelected={setSelected}
        />
      ))}
    </RadioGroup>
  </FormControl>
);

RadiosInput.propTypes = {
  options: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
};

export default RadiosInput;
