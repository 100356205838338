import 'react-toastify/dist/ReactToastify.css';

import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useContext } from 'react';
import ReactModal from 'react-modal';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

import { ProtectedRoute } from '../../components';
import Join from '../../components/Join';
import { PATHS, REACT_APP_STRIPE_PUBLISHABLE_KEY } from '../../constants';
import { ConstantsContext } from '../../contexts';
import { toPath } from '../../utils/transform';
import CalendarIntermediate from '../Calendar/CalendarIntermediate';
import CalendarView from '../Calendar/CalendarView';
import Contact from '../Contact/Contact';
import Home from '../Home/Home';
import LabelsView from '../Labels/LabelsView';
import Login from '../Login/Login';
import Organizations from '../Organizations/Organizations';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import SetActiveContext from '../SetActiveContext/SetActiveContext';
import SetActiveTheme from '../SetActiveTheme/SetActiveTheme';
import Signup from '../Signup';
import Survey from '../Survey';

ReactModal.setAppElement('#root');

const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLISHABLE_KEY);

const {
  CALENDAR,
  CALENDAR_INTERMEDIATE,
  CONTACT,
  HOME,
  JOIN,
  LABELS,
  LOGIN,
  SIGNUP,
  PRIVACY_POLICY,
  SURVEY,
  ORGANIZATIONS,
  SET_ACTIVE_CONTEXT,
  SET_ACTIVE_THEME,
} = PATHS;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column;
`;

const AppContainer = styled(Container)`
  flex-grow: 1;
  body {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    margin: 0 !important;
  }
`;

const App = () => {
  const { constants } = useContext(ConstantsContext);
  if (constants === null) {
    return <LinearProgress />;
  }

  return (
    <BrowserRouter>
      <Elements stripe={stripePromise}>
        <ToastContainer />
        <Wrapper>
          <AppContainer>
            <Switch>
              <ProtectedRoute exact path={toPath(HOME)} component={Home} />
              <ProtectedRoute
                exact
                path={toPath(SET_ACTIVE_CONTEXT)}
                component={SetActiveContext}
              />
              <ProtectedRoute
                exact
                path={toPath(SET_ACTIVE_THEME)}
                component={SetActiveTheme}
              />
              <ProtectedRoute path={toPath(SURVEY)} component={Survey} />
              <ProtectedRoute
                path={toPath(ORGANIZATIONS)}
                component={Organizations}
              />
              <ProtectedRoute
                exact
                path={toPath(CALENDAR)}
                component={CalendarView}
              />
              <Route
                exact
                path={toPath(PRIVACY_POLICY)}
                component={PrivacyPolicy}
              />
              <Route exact path={toPath(LOGIN)} component={Login} />
              <Route exact path={toPath(SIGNUP)} component={Signup} />
              <Route exact path={toPath(JOIN)} component={Join} />
              <Route exact path={toPath(LABELS)} component={LabelsView} />
              <Route
                exact
                path={toPath(CALENDAR_INTERMEDIATE)}
                component={CalendarIntermediate}
              />
              <Route exact path={toPath(CONTACT)} component={Contact} />
            </Switch>
          </AppContainer>
        </Wrapper>
      </Elements>
    </BrowserRouter>
  );
};

export default App;
