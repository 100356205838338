import moment from 'moment';

export function toPath(...parts) {
  return `/${parts.join('/')}`;
}

export function getFormattedDate(date) {
  return moment(date).format('MMM Do, YYYY');
}

export function getBaseSurveyUrl({ orgId, groupId, teamRosterId }) {
  return `/organizations/${orgId}/groups/${groupId}/team-rosters/${teamRosterId}/surveys`;
}

export function getRawNumber(number) {
  return number.replace(/\D/g, '');
}

export function getRawNumbersAndCharacters(string) {
  return string.replace(/[^A-Za-z0-9]/gi, '');
}
