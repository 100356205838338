import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const ActionWrapper = styled.button`
  background-color: transparent;
  color: ${({
    theme: {
      palette: { text },
    },
  }) => text.primary};
  border: none;
  cursor: pointer;
  width: fit-content;
  padding: 0;
  :focus {
    outline: none;
  }
`;

const Action = ({ onClick, children, className }) => (
  <ActionWrapper onClick={onClick} className={className}>
    {children}
  </ActionWrapper>
);

Action.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Action.defaultProps = { className: null };

export default Action;
