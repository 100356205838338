import PropTypes from 'prop-types';
import React from 'react';

import { getComponentForLabel } from './inputs';

const DynamicFields = ({ fields, values, onValueChange }) => {
  // eslint-disable-next-line react/prop-types
  const renderLabel = ({ id, type, name }) => {
    const Component = getComponentForLabel(type);
    const onChange = ({ target: { value } }) => onValueChange({ id, value });
    const value = values[id] || '';
    const key = `${name} - ${id}`;
    return (
      <Component
        key={key}
        id={key}
        value={value}
        label={name}
        onChange={onChange}
      />
    );
  };

  if (!fields.length) return null;
  return <>{fields.map(renderLabel)}</>;
};

DynamicFields.propTypes = {
  fields: PropTypes.array,
  values: PropTypes.object,
  onValueChange: PropTypes.func.isRequired,
};

DynamicFields.defaultProps = {
  fields: [],
  values: {},
};

export default DynamicFields;
