import 'react-big-calendar/lib/css/react-big-calendar.css';

import DateFnsUtils from '@date-io/date-fns';
import AppBar from '@material-ui/core/AppBar';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { toFinite, toUpper } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import ReactModal from 'react-modal';
import RRuleGenerator from 'react-rrule-generator';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useEvents, useEventsStatus } from '../../store/Events/selectors';
import {
  useCreateEvent,
  useFetchEvents,
  useRemoveEvent,
  useUpdateEvent,
} from '../../store/Events/thunks';
import { useGroupsForOrg, useGroupsStatus } from '../../store/Groups/selectors';
import {
  useOrderedOrganizations,
  useOrganizationsStatus,
} from '../../store/Organizations/selectors';

const localizer = momentLocalizer(moment);

const StyledBackdrop = styled(Backdrop)`
  z-index: 1000;
`;

const CalendarMain = styled.div`
  padding: 20px;
  & .rbc-btn-group,
  & .rbc-toolbar-label {
    width: 100%;
    justify-content: center;
    display: inline-flex !important;
    margin-top: 5px;
  }
`;

const StyledCalendar = styled(Calendar)`
  height: 500px;
`;

const SelectOrg = styled.div`
  padding: 20px;
`;

const GroupSelect = styled.div`
  margin-left: 10px;
  display: inline-block;
`;

const DateTime = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CreateButton = styled(Button)`
  margin-top: 10px;
  display: block;
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '530px',
    width: '80vw',
  },
  overlay: {
    zIndex: 111,
  },
};

const CalendarView = ({ fromApp }) => {
  const organizations = useOrderedOrganizations();
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const groups = useGroupsForOrg(selectedOrgId);
  const events = useEvents();
  const organizationsStatus = useOrganizationsStatus();
  const groupsStatus = useGroupsStatus();
  const eventsStatus = useEventsStatus();
  const fetchEvents = useFetchEvents();
  const createEvent = useCreateEvent();
  const updateEvent = useUpdateEvent();
  const removeEvent = useRemoveEvent();
  const [newEvent, setNewEvent] = useState({});
  const [editingEvent, setEditingEvent] = useState({});
  const [isRecurring, setIsRecurring] = useState(false);
  const [recurrence, setRecurrence] = useState('Daily');
  const [customRule, setCustomRule] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [view, setView] = useState(fromApp ? 'day' : 'month');
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (
      selectedOrgId === null &&
      organizations !== null &&
      organizations.length > 0
    ) {
      setSelectedOrgId(organizations[0].id);
    }
  }, [selectedOrgId, organizations]);

  useEffect(() => {
    if (selectedGroupId === null && groups !== null && groups.length > 0) {
      setSelectedGroupId(groups[0].id);
    }
  }, [selectedGroupId, groups]);

  useEffect(() => {
    let adder = 'month';
    switch (view) {
      case 'month' || 'agenda':
      default:
        adder = 'month';
        break;
      case 'day':
        adder = 'day';
        break;
      case 'week':
        adder = 'week';
    }
    setStartDate(moment(currentDate).subtract(1, adder).toDate());
    setEndDate(moment(currentDate).add(1, adder).toDate());
  }, [view, currentDate]);

  useEffect(() => {
    if (
      selectedOrgId == null ||
      selectedGroupId == null ||
      !startDate ||
      !endDate
    ) {
      return;
    }

    const startDateIso = moment(startDate).toISOString();
    const endDateIso = moment(endDate).toISOString();
    fetchEvents({
      orgId: selectedOrgId,
      groupId: selectedGroupId,
      startDate: startDateIso,
      endDate: endDateIso,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrgId, selectedGroupId, startDate, endDate, refresh]);

  const isWorking =
    organizationsStatus.isLoading ||
    groupsStatus.isLoading ||
    eventsStatus.isLoading;

  const handleOrgChange = (event) => {
    const id = toFinite(event.target.value);
    setSelectedOrgId(id);
  };

  const handleGroupChange = (event) => {
    const id = toFinite(event.target.value);
    setSelectedGroupId(id);
  };

  const onSelectSlot = useCallback((slot) => {
    setNewEvent({
      title: '',
      start: slot.start,
      end: slot.end,
      isAddingNewEvent: true,
    });
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      const payload = {
        ...newEvent,
      };
      let rule = null;
      if (isRecurring) {
        if (recurrence && recurrence !== 'Custom') {
          rule = `RRULE:FREQ=${toUpper(recurrence)};INTERVAL=1`;
        } else {
          rule = customRule;
        }
      }
      if (rule) {
        payload.recurrence = [rule];
      }
      await createEvent({
        orgId: selectedOrgId,
        groupId: selectedGroupId,
        ...payload,
      });
      setRefresh(!refresh);
      setNewEvent({});
    } catch (err) {
      toast.error('Could not create the event');
    }
  }, [
    newEvent,
    isRecurring,
    createEvent,
    selectedOrgId,
    selectedGroupId,
    refresh,
    recurrence,
    customRule,
  ]);

  const handleSubmitEdit = useCallback(async () => {
    try {
      await updateEvent({
        orgId: selectedOrgId,
        groupId: selectedGroupId,
        eventId: editingEvent.id,
        ...editingEvent,
      });
      setRefresh(!refresh);
      setEditingEvent({});
    } catch (err) {
      toast.error('Could not update the event');
    }
  }, [selectedGroupId, selectedOrgId, editingEvent, updateEvent, refresh]);

  const handleDelete = useCallback(async () => {
    try {
      await removeEvent({
        orgId: selectedOrgId,
        groupId: selectedGroupId,
        eventId: editingEvent.id,
      });
      setEditingEvent({});
      setRefresh(!refresh);
    } catch (err) {
      toast.error('Could not delete the event');
    }
  }, [editingEvent, selectedOrgId, selectedGroupId, removeEvent, refresh]);

  const onSelectEvent = useCallback((event) => {
    const e = {
      id: event.resource.id,
      title: event.title,
      start: new Date(event.start),
      end: new Date(event.end),
      isEditingEvent: true,
    };
    setEditingEvent(e);
  }, []);

  return (
    <div>
      <StyledBackdrop open={isWorking}>
        <CircularProgress />
      </StyledBackdrop>
      {!fromApp && (
        <AppBar position="static">
          <Toolbar>
            <IconButton edge="start">
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
      <SelectOrg>
        <FormControl>
          <InputLabel id="org-select">Organization</InputLabel>
          <Select
            labelId="org-select"
            id="org-select"
            value={selectedOrgId || ''}
            onChange={handleOrgChange}
          >
            {organizations != null &&
              organizations.map((org) => (
                <MenuItem key={org.id} value={org.id}>
                  {org.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <GroupSelect>
          <FormControl>
            <InputLabel id="group-select">Group</InputLabel>
            <Select
              labelId="group-select"
              id="group-select"
              value={selectedGroupId || ''}
              onChange={handleGroupChange}
            >
              {groups.map((g) => (
                <MenuItem key={g.id} value={g.id}>
                  {g.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GroupSelect>
      </SelectOrg>
      <CalendarMain>
        {selectedOrgId != null && selectedGroupId != null && (
          <div>
            <StyledCalendar
              defaultView={fromApp ? Views.DAY : Views.MONTH}
              localizer={localizer}
              events={Object.values(events).map((e) => ({
                title: e.summary,
                start: new Date(e.start.dateTime),
                end: new Date(e.end.dateTime),
                resource: e,
              }))}
              selectable
              popup
              showMultiDayTimes
              onSelectEvent={onSelectEvent}
              onSelectSlot={onSelectSlot}
              dayLayoutAlgorithm="no-overlap"
              views={
                fromApp ? ['day', 'agenda'] : ['month', 'week', 'day', 'agenda']
              }
              onNavigate={setCurrentDate}
              date={currentDate}
              onView={setView}
            />
          </div>
        )}
      </CalendarMain>
      <ReactModal
        isOpen={newEvent && newEvent.isAddingNewEvent}
        style={customStyles}
        shouldCloseOnOverlayClick
        onRequestClose={() =>
          setNewEvent({ ...newEvent, isAddingNewEvent: false })
        }
      >
        <TextField
          label="Title"
          value={newEvent.title}
          onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateTime>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              label="Start date"
              value={newEvent.start}
              onChange={(start) => setNewEvent({ ...newEvent, start })}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardTimePicker
              margin="normal"
              label="Start time"
              value={newEvent.start}
              onChange={(start) => setNewEvent({ ...newEvent, start })}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
            />
          </DateTime>
          <DateTime>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              label="End date"
              value={newEvent.end}
              onChange={(end) => setNewEvent({ ...newEvent, end })}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardTimePicker
              margin="normal"
              label="End time"
              value={newEvent.end}
              onChange={(end) => setNewEvent({ ...newEvent, end })}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
            />
          </DateTime>
        </MuiPickersUtilsProvider>
        <label>
          <input
            type="checkbox"
            checked={isRecurring}
            onChange={() => {
              setIsRecurring(!isRecurring);
            }}
          />
          Recurring Event
        </label>
        {isRecurring && (
          <div>
            <select
              value={recurrence}
              onChange={(e) => setRecurrence(e.target.value)}
            >
              <option value="Daily">Daily</option>
              <option value="Weekly">Weekly</option>
              <option value="Monthly">Monthly</option>
              <option value="Yearly">Yearly</option>
              <option value="Custom">Custom</option>
            </select>
            {recurrence === 'Custom' && (
              <RRuleGenerator
                onChange={(rule) => {
                  setCustomRule(rule);
                }}
              />
            )}
          </div>
        )}
        <CreateButton onClick={handleSubmit} color="primary">
          CREATE
        </CreateButton>
      </ReactModal>
      <ReactModal
        isOpen={editingEvent && editingEvent.isEditingEvent}
        style={customStyles}
        shouldCloseOnOverlayClick
        onRequestClose={() =>
          setEditingEvent({ ...editingEvent, isEditingEvent: false })
        }
      >
        <TextField
          label="Title"
          value={editingEvent.title}
          onChange={(e) =>
            setEditingEvent({ ...editingEvent, title: e.target.value })
          }
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateTime>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              label="Start date"
              value={editingEvent.start}
              onChange={(start) => {
                setEditingEvent({ ...editingEvent, start });
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardTimePicker
              margin="normal"
              label="Start time"
              value={editingEvent.start}
              onChange={(start) => setEditingEvent({ ...editingEvent, start })}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
            />
          </DateTime>
          <DateTime>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              label="End date"
              value={editingEvent.end}
              onChange={(end) => setEditingEvent({ ...editingEvent, end })}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardTimePicker
              margin="normal"
              label="End time"
              value={editingEvent.end}
              onChange={(end) => setEditingEvent({ ...editingEvent, end })}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
            />
          </DateTime>
          <Button onClick={handleSubmitEdit} color="primary">
            SUBMIT
          </Button>
          <Button onClick={handleDelete} color="secondary">
            DELETE
          </Button>
        </MuiPickersUtilsProvider>
      </ReactModal>
    </div>
  );
};

CalendarView.propTypes = {
  fromApp: PropTypes.object,
};

CalendarView.defaultProps = {
  fromApp: null,
};

export default CalendarView;
