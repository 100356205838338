import { schema } from 'normalizr';

import { groupsCollectionSchema } from '../Groups/schema';

export const name = 'organizations';
export const organizationSchema = new schema.Entity(
  name,
  {
    groups: groupsCollectionSchema,
  },
  {
    processStrategy: (entity) => ({ groups: [], ...entity }),
  },
);

export const organizationsCollectionSchema = new schema.Array(
  organizationSchema,
);
