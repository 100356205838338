import { configureStore } from '@reduxjs/toolkit';

import eventsReducer from './Events/slice';
import groupsReducer from './Groups/slice';
import organizationsReducer from './Organizations/slice';
import stripeSubscriptionsReducer from './StripeSubscriptions/slice';
import surveyQuestionsReducer from './SurveyQuestions/slice';
import surveysReducer from './Surveys/slice';
import teamRostersReducer from './TeamRosters/slice';
import usersReducer from './Users/slice';

export default configureStore({
  reducer: {
    events: eventsReducer,
    groups: groupsReducer,
    organizations: organizationsReducer,
    surveys: surveysReducer,
    surveyQuestions: surveyQuestionsReducer,
    users: usersReducer,
    teamRosters: teamRostersReducer,
    stripeSubscriptions: stripeSubscriptionsReducer,
  },
});
