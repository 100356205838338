import { useCallback, useEffect, useReducer } from 'react';

import { fetch } from '../utils/api';

const ACTIONS = {
  LOADING: 'LOADING',
  FULFILLED: 'FULFILLED',
  ERROR: 'ERROR',
};

const initialState = {
  data: undefined,
  isLoading: false,
  error: null,
};

const reducer = (state, { type, data, error }) => {
  switch (type) {
    case ACTIONS.LOADING:
      return { ...state, isLoading: true };
    case ACTIONS.FULFILLED:
      return { ...state, isLoading: false, error: null, data };
    case ACTIONS.ERROR:
      return { ...state, isLoading: false, error };
    default:
      throw new Error(`Action ${type} not supported`);
  }
};

export const useRequest = (url) => {
  const [store, dispatch] = useReducer(reducer, initialState);

  const fetchData = useCallback(async () => {
    dispatch({ type: ACTIONS.LOADING });
    try {
      const data = await fetch(url);
      dispatch({ type: ACTIONS.FULFILLED, data });
    } catch (error) {
      dispatch({ type: ACTIONS.ERROR, error });
    }
  }, [url]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return store;
};
