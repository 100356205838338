import axiosBase from 'axios';

import { QUERY, REACT_APP_BASE_API_URL } from '../constants';

const { TOKEN } = QUERY;

const axios = axiosBase.create();

axios.interceptors.request.use((config) => {
  const token = window.localStorage.getItem(TOKEN);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const initializeAxios = (jwt) => {
  axios.defaults.headers.common.Authorization = `Bearer ${jwt}`;
};

export const getEndpoint = (url) => {
  const baseEndpoint = REACT_APP_BASE_API_URL;

  return `${baseEndpoint}${url.startsWith('/') ? '' : '/'}${url}`;
};

export const fetch = async (url, params = {}) => {
  const endpoint = getEndpoint(url);
  const options = { params, withCredentials: false };
  const { data } = await axios.get(endpoint, options);
  return data;
};

export const post = async (url, body = {}, params = {}) => {
  const endpoint = getEndpoint(url);
  const options = { params, withCredentials: false };
  const { data } = await axios.post(endpoint, body, options);
  return data;
};

export const patch = async (url, body = {}, params = {}) => {
  const endpoint = getEndpoint(url);
  const options = { params, withCredentials: false };
  const { data } = await axios.patch(endpoint, body, options);
  return data;
};

export const put = async (url, body = {}, params = {}) => {
  const endpoint = getEndpoint(url);
  const options = { params, withCredentials: false };
  const { data } = await axios.put(endpoint, body, options);
  return data;
};

export const del = async (url, params = {}) => {
  const endpoint = getEndpoint(url);
  const options = { params, withCredentials: false };
  const { data } = await axios.delete(endpoint, options);
  return data;
};

export default {
  initializeAxios,
  getEndpoint,
  fetch,
  post,
  patch,
  del,
};
