import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding-top: ${({ theme: { spacing } }) => spacing(4)}px;
  height: calc(100% - ${({ theme: { spacing } }) => spacing(2)});
`;

export default Container;
