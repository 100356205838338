import BasePaper from '@material-ui/core/Paper';
import styled from 'styled-components';

const Paper = styled(BasePaper)`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: unset;
  ${({ theme: { breakpoints } }) => breakpoints.up('md')} {
    background-color: ${({ theme: { palette } }) =>
      palette.background.transparent};
    max-width: 750px;
    padding: ${({ theme }) => theme.spacing(15)}px;
  }
  &.MuiPaper-elevation1 {
    box-shadow: none;
  }
`;

export default Paper;
