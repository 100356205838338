import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { DockingButton, Logo, Row, TopAlert } from '../../components';
import { post } from '../../utils/api';
import { useSetToken, useToken } from '../../utils/hooks';

const VerticalCenter = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const Subtitle = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-left: 70px;
`;

const NumberInput = styled(NumberFormat)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`;

const Instructions = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`;

const ContinueButton = styled(DockingButton)`
  width: 100%;
`;

// TODO remove
const getRawNumber = (number) => number.replace(/\D/g, '');

const hasLength = (number, length) => getRawNumber(number).length >= length;

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState(null);
  const [step, setStep] = useState(0);
  const [sentAlertOpen, setSentAlertOpen] = useState(false);
  const history = useHistory();
  const token = useToken();
  const setToken = useSetToken();

  useEffect(() => {
    if (token != null) {
      history.push('/');
    }
  });

  const handlePhoneNumberSubmit = async () => {
    try {
      await post('/auth/send-code', {
        phoneNumber: getRawNumber(phoneNumber),
      });
      setError(null);
      setStep(1);
    } catch (err) {
      setError('Invalid user');
    }
  };

  const handleVerificationCodeSubmit = async () => {
    try {
      const res = await post('/auth/verify-code', {
        phoneNumber: getRawNumber(phoneNumber),
        verificationCode,
      });
      const { token: responseToken } = res;

      setToken(responseToken);
      setError(null);
      history.push('/');
    } catch (err) {
      setError('Invalid Verification Code');
    }
  };

  const handleResendCode = async () => {
    await handlePhoneNumberSubmit();
    setSentAlertOpen(true);
  };

  const {
    label,
    value,
    description,
    setValue,
    format,
    isDisabled,
    submitLabel,
    onSubmit,
  } = [
    {
      label: 'Enter your phone number',
      value: phoneNumber,
      description:
        'Tapping “Continue” will send you an SMS. Message and data rates may apply.',
      setValue: setPhoneNumber,
      format: '(###) ###-####',
      isDisabled: !hasLength(phoneNumber, 10),
      submitLabel: 'Sign in',
      onSubmit: handlePhoneNumberSubmit,
    },
    {
      label: 'Verification Code',
      value: verificationCode,
      description: (
        <>
          We texted you a verification code.&nbsp;
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link component="button" onClick={handleResendCode}>
            Send again.
          </Link>
        </>
      ),
      setValue: setVerificationCode,
      format: '######',
      isDisabled: !hasLength(verificationCode, 6),
      submitLabel: 'Verify',
      onSubmit: handleVerificationCodeSubmit,
    },
  ][step];

  const signupLink =
    step === 0 ? (
      <Row>
        <Link href="/signup">Signup</Link>
      </Row>
    ) : null;

  return (
    <>
      <TopAlert
        message="Code sent via SMS!"
        open={sentAlertOpen}
        setOpen={setSentAlertOpen}
      />
      <VerticalCenter>
        <Grid container>
          <Grid item xs={false} md={1} />
          <Grid item xs={12} md={4}>
            <Logo />
            <Subtitle>Manage everything about your team in one place.</Subtitle>
          </Grid>
          <Grid item xs={false} md={2} />
          <Grid item xs={12} md={4}>
            <NumberInput
              error={error !== null}
              label={label}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              format={format}
              mask="_"
              customInput={TextField}
              helperText={error}
            />
            <Instructions>{description}</Instructions>
            <ContinueButton onClick={onSubmit} disabled={isDisabled}>
              {submitLabel}
            </ContinueButton>
            {signupLink}
          </Grid>
          <Grid item xs={false} md={1} />
        </Grid>
      </VerticalCenter>
    </>
  );
};

export default Login;
