export const PATHS = {
  CALENDAR: 'calendar',
  CALENDAR_INTERMEDIATE: 'calendar-intermediate',
  COMPLETE: 'complete',
  CONTACT: 'contact',
  HOME: '',
  JOIN: 'join',
  LOGIN: 'login',
  SIGNUP: 'signup',
  STEP: 'step',
  SURVEY: 'survey',
  LABELS: 'labels',
  PRIVACY_POLICY: 'privacy',
  SET_ACTIVE_CONTEXT: 'set-active-context',
  SET_ACTIVE_THEME: 'theme',
  ORGANIZATIONS: 'organizations',
  THEME: 'theme',
};
