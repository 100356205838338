import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

import { name } from './schema';
import { createEvent, fetchEvents, updateEvent } from './thunks';

const eventAdapter = createEntityAdapter();

const setIsLoading = (state) => {
  state.isLoading = true;
};

const setNotLoading = (state) => {
  state.isLoading = false;
};

const setError = (state, action) => {
  state.isLoading = false;
  state.error = action.error.message;
};

const initialState = eventAdapter.getInitialState({
  isLoading: false,
  error: null,
});

const addEvents = (state, action) => {
  state.isLoading = false;
  const { events = {} } = action.payload.entities;
  if (!isEmpty(events)) {
    eventAdapter.upsertMany(state, Object.values(events));
  }
};

export const eventsSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [fetchEvents.pending]: setIsLoading,
    [fetchEvents.fulfilled]: addEvents,
    [fetchEvents.rejected]: setError,

    [createEvent.pending]: setIsLoading,
    [createEvent.fulfilled]: setNotLoading,
    [createEvent.rejected]: setError,

    [updateEvent.pending]: setIsLoading,
    [updateEvent.fulfilled]: setNotLoading,
    [updateEvent.rejected]: setError,
  },
});

const selectEvents = ({ events }) => events;

const selectEventsStatus = createSelector(
  selectEvents,
  ({ isLoading, error }) => ({ isLoading, error }),
);

export const selectors = {
  ...eventAdapter.getSelectors(selectEvents),
  selectEvents,
  selectEventsStatus,
};

export const { reset } = eventsSlice.actions;

export default eventsSlice.reducer;
