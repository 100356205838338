import LinearProgress from '@material-ui/core/LinearProgress';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export const SignupProgress = styled(LinearProgress)`
  height: 6px;
  margin-top: ${({ theme }) => theme.survey.cardSpacing / 2}px;
  margin-bottom: ${({ theme }) => theme.survey.cardSpacing / 2}px;
  width: 100%;
`;

const Progress = ({ step, totalSteps }) => {
  const progress = Math.floor(((step + 1) / (totalSteps + 2)) * 100);

  return <SignupProgress variant="determinate" value={progress} />;
};

Progress.propTypes = {
  step: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
};

export default Progress;
