import PropTypes from 'prop-types';
import React from 'react';

import NavBarLinks from '../NavBarLinks';
import { StyledMenu } from './StyledMenu';

const Menu = ({ isOpen, setIsOpen }) => {
  return (
    <StyledMenu isOpen={isOpen}>
      <NavBarLinks onPress={() => setIsOpen(false)} />
    </StyledMenu>
  );
};

Menu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func,
};

Menu.defaultProps = {
  setIsOpen: () => {},
};

export default Menu;
