import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import capitalize from 'lodash/capitalize';
import map from 'lodash/map';
import upperCase from 'lodash/upperCase';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Container, DockingButton, Paper } from '../../components';
import { useActiveContext, useActiveTheme, useConstants } from '../../hooks';
import { useUpdateOrganization } from '../../store/Organizations/thunks';
import Preview from './Preview';

const Subtitle = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`;

const ContinueButton = styled(DockingButton)`
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`;

const SetActiveTheme = () => {
  const history = useHistory();
  const updateOrganization = useUpdateOrganization();
  const { activeOrganization } = useActiveContext();
  const { activeTheme, setActiveTheme } = useActiveTheme();
  const [localThemeId, setLocalThemeId] = useState(activeTheme?.id);
  const { constants } = useConstants();
  const { themes = [] } = constants;
  const themesById = useMemo(
    () =>
      themes.reduce((acc, current) => ({ ...acc, [current.id]: current }), {}),
    [themes],
  );

  const onSubmit = () => {
    if (localThemeId) {
      const theme = themesById[localThemeId];
      setActiveTheme(theme);
      updateOrganization({
        orgId: activeOrganization,
        themeId: localThemeId,
      });
      history.push('/');
    }
  };

  return (
    <Container>
      <Paper square>
        <Typography variant="h5">Select the theme</Typography>
        <Subtitle align="center">
          Choose the app’s background color and select from the available
          themes.
        </Subtitle>
        <FormControl>
          <InputLabel id="theme-select">Background color</InputLabel>
          <Select
            labelId="theme-select"
            name="activeTheme"
            value={localThemeId}
            onChange={({ target: { value: themeId } }) =>
              setLocalThemeId(themeId)
            }
          >
            {themes != null &&
              map(themes, ({ id, name }) => (
                <MenuItem key={`theme-${id}`} value={id}>
                  {capitalize(upperCase(name))}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Subtitle align="center">Preview:</Subtitle>
        <Preview themeValues={themesById[localThemeId]} />
        <ContinueButton onClick={onSubmit} disabled={!localThemeId}>
          Select Theme
        </ContinueButton>
      </Paper>
    </Container>
  );
};

export default SetActiveTheme;
