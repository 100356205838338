import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { useDispatch } from 'react-redux';

import { fetch, initializeAxios, post } from '../../utils/api';
import { setToken } from '../../utils/hooks';
import { getRawNumber } from '../../utils/transform';
import { organizationsCollectionSchema } from '../Organizations/schema';
import { teamRostersCollectionSchema } from '../TeamRosters/schema';
import { name, userSchema } from './schema';

export const fetchUser = createAsyncThunk(
  `${name}/fetch`,
  async ({ userId }) => {
    const response = await fetch(`/users/${userId}`);

    const responseSchema = {
      userData: userSchema,
      teamRosters: teamRostersCollectionSchema,
      organizations: organizationsCollectionSchema,
    };

    const normalizedData = normalize(response, responseSchema);
    return normalizedData;
  },
);

export const createUser = createAsyncThunk(
  `${name}/createUser`,
  async ({ fullName, phoneNumber, email }) =>
    post('/users', {
      fullName,
      phoneNumber: getRawNumber(phoneNumber),
      email,
    }),
);

export const getVerificationCode = createAsyncThunk(
  `${name}/getVerificationCode`,
  async ({ phoneNumber }) =>
    post('/auth/send-code', {
      phoneNumber: getRawNumber(phoneNumber),
    }),
);

export const sendVerificationCode = createAsyncThunk(
  `${name}/sendVerificationCode`,
  async ({ phoneNumber, verificationCode }) => {
    const { token } = await post('/auth/verify-code', {
      phoneNumber: getRawNumber(phoneNumber),
      verificationCode,
    });
    initializeAxios(token);
    setToken(token);

    return token;
  },
);

export const useFetchUser = () => {
  const dispatch = useDispatch();
  return (userId) => dispatch(fetchUser({ userId }));
};

export const useCreateUser = () => {
  const dispatch = useDispatch();
  return (args) => dispatch(createUser(args));
};

export const useGetVerificationCode = () => {
  const dispatch = useDispatch();
  return (args) => dispatch(getVerificationCode(args));
};

export const useSendVerificationCode = () => {
  const dispatch = useDispatch();
  return (args) => dispatch(sendVerificationCode(args));
};
