import { Link } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';

import { TextInput } from '../../components/inputs';
import {
  useGetVerificationCode,
  useSendVerificationCode,
} from '../../store/Users/thunks';
import { isValidVerificationCode } from '../../utils/validators';
import { SubmitButton, Subtext, Subtitle } from './SignupComponents';

const Verification = ({ form, goToNextStep, setError, onFormChanged }) => {
  const sendVerificationCode = useSendVerificationCode();
  const getVerificationCode = useGetVerificationCode();
  const [isLoading, setIsLoading] = useState(false);

  const { phoneNumber, verificationCode } = form;

  const handleResendCode = () => getVerificationCode({ phoneNumber });

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      const { payload: token } = await sendVerificationCode({
        phoneNumber,
        verificationCode,
      });

      if (!token) {
        throw new Error();
      }

      goToNextStep();
    } catch {
      setError('Invalid Verification Code');
    } finally {
      setIsLoading(false);
    }
  };

  const isButtonDisabled =
    !isValidVerificationCode(verificationCode) || isLoading;

  return (
    <>
      <Subtitle>User Verification</Subtitle>
      <NumberFormat
        id="verificationCode"
        label="Enter Verification Code"
        value={verificationCode}
        onChange={onFormChanged}
        format="######"
        mask="_"
        customInput={TextInput}
      />
      <Subtext>
        We texted you a verification code.&nbsp;
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link component="button" onClick={handleResendCode}>
          Send again.
        </Link>
      </Subtext>
      <SubmitButton
        disabled={isButtonDisabled}
        isLoading={isLoading}
        onClick={handleSubmit}
      >
        Next
      </SubmitButton>
    </>
  );
};

Verification.propTypes = {
  form: PropTypes.object.isRequired,
  goToNextStep: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  onFormChanged: PropTypes.func.isRequired,
};

export default Verification;
