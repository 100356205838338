import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

import { fetchSurvey } from '../Surveys/thunks';
import { name } from './schema';
import { updateSurveyQuestion } from './thunks';

const surveyQuestionAdapter = createEntityAdapter();

const setIsLoading = (state) => {
  state.isLoading = true;
};

const setError = (state, action) => {
  state.isLoading = false;
  state.error = action.error.message;
};

const initialState = surveyQuestionAdapter.getInitialState({
  isLoading: false,
  error: null,
});

const addSurveyQuestions = (state, action) => {
  state.isLoading = false;
  state.error = null;
  const { surveyQuestions = {} } = action.payload.entities;
  if (!isEmpty(surveyQuestions)) {
    surveyQuestionAdapter.upsertMany(state, surveyQuestions);
  }
};

export const surveyQuestionsSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [fetchSurvey.pending]: setIsLoading,
    [fetchSurvey.fulfilled]: addSurveyQuestions,
    [fetchSurvey.rejected]: setError,

    [updateSurveyQuestion.pending]: setIsLoading,
    [updateSurveyQuestion.fulfilled]: addSurveyQuestions,
    [updateSurveyQuestion.rejected]: setError,
  },
});

const selectSurveyQuestions = ({ surveyQuestions }) => surveyQuestions;

const selectSurveyQuestionsStatus = createSelector(
  selectSurveyQuestions,
  ({ isLoading, error }) => ({ isLoading, error }),
);

export const selectors = {
  ...surveyQuestionAdapter.getSelectors(selectSurveyQuestions),
  selectSurveyQuestions,
  selectSurveyQuestionsStatus,
};

export const { reset } = surveyQuestionsSlice.actions;

export default surveyQuestionsSlice.reducer;
