import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

import { fetchUser } from '../Users/thunks';
import { name } from './schema';

const teamRosterAdapter = createEntityAdapter();

const setIsLoading = (state) => {
  state.isLoading = true;
};

const setError = (state, action) => {
  state.isLoading = false;
  state.error = action.error.message;
};

const initialState = teamRosterAdapter.getInitialState({
  isLoading: false,
  error: null,
});

const addTeamRosters = (state, action) => {
  state.isLoading = false;
  const { teamRosters = {} } = action.payload.entities;
  if (!isEmpty(teamRosters)) {
    teamRosterAdapter.upsertMany(state, Object.values(teamRosters));
  }
};

export const teamRostersSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [fetchUser.pending]: setIsLoading,
    [fetchUser.fulfilled]: addTeamRosters,
    [fetchUser.rejected]: setError,
  },
});

const selectTeamRosters = ({ teamRosters }) => teamRosters;

const selectTeamRostersStatus = createSelector(
  selectTeamRosters,
  ({ isLoading, error }) => ({ isLoading, error }),
);

export const selectors = {
  ...teamRosterAdapter.getSelectors(selectTeamRosters),
  selectTeamRosters,
  selectTeamRostersStatus,
};

export default teamRostersSlice.reducer;
