import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import NumberFormat from 'react-number-format';

import { TextInput } from './inputs';

export const emptyGuardian = {
  fullName: '',
  phoneNumber: '',
  email: '',
};

const GuardianForm = forwardRef(({ guardian, onValueChange }, ref) => (
  <div ref={ref}>
    <TextInput
      label="Name"
      value={get(guardian, 'fullName', '')}
      onChange={({ target: { value } }) => {
        onValueChange({ field: 'fullName', value });
      }}
    />
    <NumberFormat
      label="Phone Number"
      value={get(guardian, 'phoneNumber', '')}
      onChange={({ target: { value } }) => {
        onValueChange({ field: 'phoneNumber', value });
      }}
      format="(###) ###-####"
      mask="_"
      customInput={TextInput}
    />
    <TextInput
      label="Email"
      value={get(guardian, 'email', '')}
      onChange={({ target: { value } }) => {
        onValueChange({ field: 'email', value });
      }}
    />
  </div>
));

GuardianForm.propTypes = {
  guardian: PropTypes.shape(emptyGuardian).isRequired,
  onValueChange: PropTypes.func.isRequired,
};
export default GuardianForm;
