import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

import { fetchUser } from '../Users/thunks';
import { name } from './schema';

const groupAdapter = createEntityAdapter();

const setIsLoading = (state) => {
  state.isLoading = true;
};

const setError = (state, action) => {
  state.isLoading = false;
  state.error = action.error.message;
};

const initialState = groupAdapter.getInitialState({
  isLoading: false,
  error: null,
});

const addGroups = (state, action) => {
  state.isLoading = false;
  const { groups = {} } = action.payload.entities;
  if (!isEmpty(groups)) {
    groupAdapter.upsertMany(state, Object.values(groups));
  }
};

export const groupsSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [fetchUser.pending]: setIsLoading,
    [fetchUser.fulfilled]: addGroups,
    [fetchUser.rejected]: setError,
  },
});

const selectGroups = ({ groups }) => groups;

const selectGroupsStatus = createSelector(
  selectGroups,
  ({ isLoading, error }) => ({ isLoading, error }),
);

export const selectors = {
  ...groupAdapter.getSelectors(selectGroups),
  selectGroups,
  selectGroupsStatus,
};

export default groupsSlice.reducer;
