import BaseModal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Action from './Action';
import { SubmitButton } from './inputs';

const Modal = styled(BaseModal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled(SubmitButton)`
  margin-top: 0;
  width: fit-content;
  display: inline-block;
  margin-left: 20px;
  flex: initial;
`;

const Container = styled.div`
  display: flex;
  background-color: ${({
    theme: {
      palette: { background },
    },
  }) => background.default};
  width: fit-content;
  padding: ${({ theme: { spacing } }) => spacing(5)}px;
  border-radius: 4px;
  :focus {
    outline: none;
  }
`;

const Content = styled.div``;
const Row = styled.div`
  display: flex;
  margin-top: ${({ theme: { spacing } }) => spacing(2)}px;
  justify-content: flex-end;
`;

const ConfirmationModal = ({
  title,
  message,
  children,
  confirmLabel,
  onConfirm,
  onCancel,
}) => (
  <Modal open>
    <Container>
      <Content>
        <Typography variant="h5">{title}</Typography>
        {message !== null && <Typography>{message}</Typography>}
        {children !== null && children}
        <Row>
          {onCancel !== null && <Action onClick={onCancel}>NO THANKS</Action>}
          <Button onClick={onConfirm}>{confirmLabel}</Button>
        </Row>
      </Content>
    </Container>
  </Modal>
);

ConfirmationModal.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  children: PropTypes.node,
  confirmLabel: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

ConfirmationModal.defaultProps = {
  message: null,
  children: null,
  confirmLabel: 'YES',
  onCancel: null,
};

export default ConfirmationModal;
