import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

import { name } from './schema';
import { completeSurvey, fetchSurvey } from './thunks';

const surveyAdapter = createEntityAdapter();

const setIsLoading = (state) => {
  state.isLoading = true;
};

const setError = (state, action) => {
  state.isLoading = false;
  state.error = action.error.message;
};

const initialState = surveyAdapter.getInitialState({
  isLoading: false,
  error: null,
});

const addSurveys = (state, action) => {
  state.isLoading = false;
  state.error = null;
  const { surveys = {} } = action.payload.entities;
  if (!isEmpty(surveys)) {
    surveyAdapter.upsertMany(state, surveys);
  }
};

export const surveysSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [fetchSurvey.pending]: setIsLoading,
    [fetchSurvey.fulfilled]: addSurveys,
    [fetchSurvey.rejected]: setError,

    [completeSurvey.pending]: setIsLoading,
    [completeSurvey.fulfilled]: addSurveys,
    [completeSurvey.rejected]: setError,
  },
});

const selectSurveys = ({ surveys }) => surveys;

const selectSurveysStatus = createSelector(
  selectSurveys,
  ({ isLoading, error }) => ({ isLoading, error }),
);

export const selectors = {
  ...surveyAdapter.getSelectors(selectSurveys),
  selectSurveys,
  selectSurveysStatus,
};

export const { reset } = surveysSlice.actions;

export default surveysSlice.reducer;
