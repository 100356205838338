import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import without from 'lodash/without';
import PropTypes from 'prop-types';
import React from 'react';

import SurveyOption from './SurveyOption';

const CheckboxInput = ({
  value,
  label,
  isExclusive,
  exclusiveValues,
  selected,
  setSelected,
}) => {
  const checked = selected.includes(value);

  const handleChange = () => {
    let next;
    if (isExclusive && !checked) {
      next = [value];
    } else {
      next = checked
        ? without(selected, value)
        : [...without(selected, ...exclusiveValues), value];
    }
    setSelected(next);
  };

  return (
    <SurveyOption label={label} selected={checked} onPress={handleChange} />
  );
};

CheckboxInput.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isExclusive: PropTypes.bool.isRequired,
  exclusiveValues: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
};

const CheckboxesInput = ({ options, selected, setSelected }) => {
  const exclusiveValues = options.reduce(
    (result, { id: answerId, isExclusive }) => {
      const next = [...result];
      if (isExclusive) {
        next.push(String(answerId));
      }
      return next;
    },
    [],
  );

  return (
    <FormControl component="fieldset">
      <FormGroup>
        {options.map(({ id: answerId, displayName, isExclusive }) => (
          <CheckboxInput
            key={answerId}
            value={String(answerId)}
            label={displayName}
            isExclusive={isExclusive}
            exclusiveValues={exclusiveValues}
            selected={selected}
            setSelected={setSelected}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

CheckboxesInput.propTypes = {
  options: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
};

export default CheckboxesInput;
