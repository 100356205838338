import { getRawNumber, getRawNumbersAndCharacters } from './transform';

const VERIFICATION_CODE_LENGTH = 6;

export const isValidEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const isValidPhoneNumber = (phone) => {
  const numberToTest = getRawNumber(phone);

  // eslint-disable-next-line no-useless-escape
  const phoneRegex = /^(?:\([2-9]\d{2}\)\ ?|[2-9]\d{2}(?:\-?|\ ?))[2-9]\d{2}[- ]?\d{4}$/;
  return phoneRegex.test(numberToTest);
};

export const isValidVerificationCode = (code) => {
  const codeToTest = getRawNumbersAndCharacters(code);
  return codeToTest.length === VERIFICATION_CODE_LENGTH;
};
