import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import LogoSmallImage from '../images/total-team-logo-small.png';
import { ReactComponent as Svg } from '../images/total-team-logo.svg';

export const LOGO_VARIANTS = {
  NORMAL: 'normal',
  SMALL: 'small',
};

const LogoContainer = styled(Svg)`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`;

const LogoSmall = styled.img.attrs(() => ({
  src: LogoSmallImage,
}))`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`;

const Logo = ({ height, width, variant }) => {
  const LogoComponent =
    variant === LOGO_VARIANTS.SMALL ? LogoSmall : LogoContainer;
  return <LogoComponent height={height} width={width} />;
};

Logo.propTypes = {
  height: PropTypes.any,
  width: PropTypes.any,
  variant: PropTypes.oneOf(Object.values(LOGO_VARIANTS)),
};

Logo.defaultProps = {
  height: 'auto',
  width: '100%',
  variant: LOGO_VARIANTS.NORMAL,
};

export default Logo;
