import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

import { name } from './schema';
import { fetchStripeSubscriptions } from './thunks';

const stripeSubscriptionsAdapter = createEntityAdapter();

const setIsLoading = (state) => {
  state.isLoading = true;
};

const setError = (state, action) => {
  state.isLoading = false;
  state.error = action.error.message;
};

const initialState = stripeSubscriptionsAdapter.getInitialState({
  isLoading: false,
  error: null,
});

const addStripeSubscriptions = (state, action) => {
  state.isLoading = false;
  const { payload: subscriptionObjects = {} } = action;
  if (!isEmpty(subscriptionObjects)) {
    stripeSubscriptionsAdapter.upsertMany(state, subscriptionObjects);
  }
};

export const stripeSubscriptionsSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [fetchStripeSubscriptions.pending]: setIsLoading,
    [fetchStripeSubscriptions.fulfilled]: addStripeSubscriptions,
    [fetchStripeSubscriptions.rejected]: setError,
  },
});

const selectStripeSubscriptions = ({ stripeSubscriptions }) =>
  stripeSubscriptions;

const selectStripeSubscriptionsStatus = createSelector(
  selectStripeSubscriptions,
  ({ isLoading, error }) => ({ isLoading, error }),
);

export const selectors = {
  ...stripeSubscriptionsAdapter.getSelectors(selectStripeSubscriptions),
  selectStripeSubscriptions,
  selectStripeSubscriptionsStatus,
};

export default stripeSubscriptionsSlice.reducer;
