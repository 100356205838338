import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';

import Alert from '../components/Alert';

export const AlertContext = createContext();

const initialModalState = {
  isOpen: false,
  message: '',
  severity: '',
};

const AlertContextProvider = ({ children }) => {
  const [modal, setModal] = useState(initialModalState);
  const hideModal = () => setModal(initialModalState);
  const showModal = (params) => setModal({ ...params, isOpen: true });

  const { isOpen, message, severity } = modal;
  return (
    <AlertContext.Provider value={{ showModal, hideModal }}>
      <Alert
        open={isOpen}
        message={message}
        severity={severity}
        handleClose={hideModal}
      />

      {children}
    </AlertContext.Provider>
  );
};

AlertContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default AlertContextProvider;
