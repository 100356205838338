import { useContext } from 'react';

import { ConstantsContext } from '../contexts/ConstantsContextProvider';

export function useConstants() {
  const context = useContext(ConstantsContext);
  if (!context) {
    return 'useConstants must be used within a ConstantsContext';
  }
  return context;
}
