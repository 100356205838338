import { useContext } from 'react';

import { ActiveContext } from '../contexts/ActiveContextProvider';

export function useActiveContext() {
  const context = useContext(ActiveContext);
  if (!context) {
    return 'useActiveContext must be used within a ActiveContextProvider';
  }
  return context;
}
