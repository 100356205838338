import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import {
  ActiveContextProvider,
  ActiveThemeProvider,
  AlertContextProvider,
  ConstantsContextProvider,
} from './contexts';
import App from './features/App';
import { useActiveTheme } from './hooks';
import * as serviceWorker from './serviceWorker';
import store from './store';
import { getTheme } from './theme';

require('dotenv').config();

const StyledApp = () => {
  const { activeTheme } = useActiveTheme();
  const theme = getTheme(activeTheme);
  return (
    <StyledThemeProvider theme={theme}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </MuiThemeProvider>
    </StyledThemeProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ActiveThemeProvider>
        <ActiveContextProvider>
          <AlertContextProvider>
            <ConstantsContextProvider>
              <StyledApp />
            </ConstantsContextProvider>
          </AlertContextProvider>
        </ActiveContextProvider>
      </ActiveThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
