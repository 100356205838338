import findIndex from 'lodash/findIndex';
import { useSelector } from 'react-redux';

import { useSurveyById } from '../Surveys/selectors';
import { selectors } from './slice';

const { selectEntities, selectById, selectSurveyQuestionsStatus } = selectors;

export const useSurveyQuestions = () => useSelector(selectEntities);
export const useSurveyQuestionById = (id) =>
  useSelector((state) => selectById(state, id));
export const useSurveyQuestionsStatus = () =>
  useSelector(selectSurveyQuestionsStatus);

export const useSurveyQuestionsForSurvey = (surveyId) => {
  const survey = useSurveyById(surveyId);
  const questions = useSurveyQuestions();
  return survey?.surveyQuestions?.map((questionId) => questions[questionId]);
};

export const useSurveyQuestionByStep = (surveyId, step) => {
  const questions = useSurveyQuestionsForSurvey(surveyId);
  return questions[step];
};

export const useSurveyCursor = (surveyId) => {
  const questions = useSurveyQuestionsForSurvey(surveyId);
  if (questions == null) {
    return null;
  }

  const index = findIndex(questions, ({ answers }) => answers.length === 0);
  return index < 0 ? questions.length : index;
};

export const useSurveyStepData = (surveyId, step) => {
  const surveyQuestionByStep = useSurveyQuestionByStep(surveyId, step);
  if (!surveyQuestionByStep) return null;

  const {
    id: questionId,
    question,
    answerType,
    possibleAnswers,
    answers,
  } = surveyQuestionByStep;

  return {
    questionId,
    question,
    answerType,
    possibleAnswers,
    answers: answers.map(({ surveyAnswerId }) => String(surveyAnswerId)),
  };
};
