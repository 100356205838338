import Snackbar from '@material-ui/core/Snackbar';
import PropTypes from 'prop-types';
import React from 'react';

const HIDE_SECONDS = 5;

const TopAlert = ({ message, open, setOpen }) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    autoHideDuration={HIDE_SECONDS * 1000}
    message={message}
    open={open}
    onClose={() => setOpen(false)}
  />
);

TopAlert.propTypes = {
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default TopAlert;
