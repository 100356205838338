/* eslint jsx-a11y/anchor-is-valid: 0 */
import { ListItem, ListItemText } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useNavLinks } from '../../hooks';
import LogOutButton from '../LogOutButton';
import Link from './Link';

const NavBarLinks = ({ onPress }) => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const links = useNavLinks();

  const onClick = (path) => {
    onPress();
    push(path);
  };

  return (
    <>
      {links.map(({ title, path, isActive = pathname.includes(path) }) => (
        <Link
          key={title}
          onClick={() => onClick(path)}
          color={isActive ? 'primaryText' : 'secondaryText'}
          underline={isActive ? 'always' : 'none'}
        >
          <ListItem button>
            <ListItemText primary={title} />
          </ListItem>
        </Link>
      ))}
      <LogOutButton />
    </>
  );
};

NavBarLinks.propTypes = {
  onPress: PropTypes.func,
};

NavBarLinks.defaultProps = {
  onPress: () => {},
};

export default NavBarLinks;
