import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { TextInput } from '../../components/inputs';
import { useCreateOrganization } from '../../store/Organizations/thunks';
import { SubmitButton, Subtitle } from './SignupComponents';

const Organization = ({ form, goToNextStep, setError, onFormChanged }) => {
  const createOrganization = useCreateOrganization();
  const [isLoading, setIsLoading] = useState(false);

  const { orgName } = form;

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      const { payload: org } = await createOrganization({ orgName });

      if (!org) {
        throw new Error();
      }
      form.orgId = org.id;

      goToNextStep();
    } catch {
      setError();
    } finally {
      setIsLoading(false);
    }
  };

  const isButtonDisabled = !orgName.length || isLoading;

  return (
    <>
      <Subtitle>Organization Details</Subtitle>
      <TextInput
        label="Organization Name"
        id="orgName"
        value={orgName}
        onChange={onFormChanged}
      />
      <SubmitButton
        disabled={isButtonDisabled}
        isLoading={isLoading}
        onClick={handleSubmit}
      >
        Next
      </SubmitButton>
    </>
  );
};

Organization.propTypes = {
  form: PropTypes.object.isRequired,
  goToNextStep: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  onFormChanged: PropTypes.func.isRequired,
};

export default Organization;
