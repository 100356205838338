import { useSelector } from 'react-redux';

import { selectors } from './slice';

const { selectEntities, selectGroupsStatus, selectTotal } = selectors;

export const useStripeSubscriptions = () => useSelector(selectEntities);
export const useStripeSubscriptionsStatus = () =>
  useSelector(selectGroupsStatus);
export const useStripeSubscriptionsTotal = () => useSelector(selectTotal);
