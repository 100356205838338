import { Typography } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Image } from '../../components';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

const Container = styled.div`
  display: flex;
  flex: 1;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    align-items: center;
  }
`;

const Title = styled(Typography).attrs(() => ({
  variant: 'h6',
}))`
  ${({ theme }) => theme.breakpoints.up('md')} {
    white-space: nowrap;
  }
`;

const Description = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 20px;
`;

const DATE_FORMAT = 'MMM DD, YYYY';

const SubscriptionItem = ({ org, isActive }) => {
  const { name: orgName, imageUrl, subscriptions } = org;
  const [subscription] = subscriptions;
  const {
    endDate,
    subscriptionType: {
      name: subscriptionTypeName,
      description,
      priceCents,
      interval,
    },
  } = subscription;

  const formattedEndDate = moment(endDate).format(DATE_FORMAT);
  const endDateText = isActive
    ? `Renews on ${formattedEndDate}`
    : `Expired on ${formattedEndDate}`;

  const price = formatter.format(priceCents / 100);

  return (
    <Container>
      <Image src={imageUrl} />
      <Content>
        <Description>
          <Title>
            {`${orgName} - ${subscriptionTypeName} ${
              description ? `(${description})` : ''
            }`}
          </Title>
          <Typography variant="body2">{`${price}/${interval}`}</Typography>
        </Description>
        <Typography variant="body2">{endDateText}</Typography>
      </Content>
    </Container>
  );
};

SubscriptionItem.propTypes = {
  isActive: PropTypes.bool.isRequired,
  org: PropTypes.shape({
    name: PropTypes.string,
    imageUrl: PropTypes.string,
    subscriptions: PropTypes.array,
  }).isRequired,
};
export default SubscriptionItem;
