import 'date-fns';

import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Card from './Card';

export const Form = ({ isLoading, children }) => (
  <Card>
    {isLoading && <LinearProgress />}
    {children}
  </Card>
);
Form.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
Form.defaultProps = { isLoading: false };

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
  & > div {
    background-color: transparent;
  }
`;

export const DatePicker = ({ id, value, onChange, label }) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <StyledKeyboardDatePicker
      id={id}
      value={value}
      onChange={onChange}
      margin="normal"
      variant="inline"
      fullWidth
      label={label}
      format="MM/dd/yyyy"
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
    />
  </MuiPickersUtilsProvider>
);
DatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};
DatePicker.defaultProps = {
  value: null,
};

export const TextInput = styled(TextField).attrs(() => ({
  margin: 'normal',
  variant: 'standard',
  fullWidth: true,
}))`
  color: ${({
    theme: {
      palette: {
        text: { primary },
      },
    },
  }) => primary};
  border-bottom: ;
`;

const NumberInput = styled(TextInput).attrs(() => ({
  type: 'number',
}))``;

const FIELD_FOR_TYPE = {
  number: NumberInput,
  string: TextInput,
};

export const getComponentForLabel = (type) => FIELD_FOR_TYPE[type] || TextInput;

const SubmitBtnContainer = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 1;
`;

export const SubmitButton = ({
  onClick,
  isDisabled,
  children,
  route,
  className,
}) => (
  <SubmitBtnContainer className={className}>
    <Button
      type="submit"
      variant="contained"
      color="primary"
      fullWidth
      margin="normal"
      onClick={onClick}
      disabled={isDisabled}
      to={route}
    >
      {children}
    </Button>
  </SubmitBtnContainer>
);
SubmitButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  route: PropTypes.string,
  className: PropTypes.string,
};

SubmitButton.defaultProps = {
  isDisabled: false,
  route: null,
  className: null,
};

const ImageUploaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const ImageInput = styled.input.attrs(() => ({
  accept: 'image/*',
  type: 'file',
}))`
  display: none;
`;

export const ImageUploader = ({ id, label, fileName, onChange }) => (
  <ImageUploaderContainer>
    <TextInput label={label} variant="standard" value={fileName} disabled />

    <ImageInput id={id} onChange={onChange} />
    <label htmlFor={id}>
      <Button
        variant="contained"
        color="secondary"
        component="span"
        startIcon={<CloudUploadIcon />}
        style={{ height: '56px', width: '112px' }}
      >
        Upload
      </Button>
    </label>
  </ImageUploaderContainer>
);
ImageUploader.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
