import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Logo from './Logo';

const Subtitle = styled(Typography)`
  line-height: 24px;
`;

const Header = ({ subtext }) => (
  <>
    <Logo height={200} />
    <Subtitle>{subtext}</Subtitle>
  </>
);

Header.propTypes = {
  subtext: PropTypes.string,
};

Header.defaultProps = {
  subtext: null,
};

export default Header;
