import { useContext } from 'react';

import { ActiveThemeContext } from '../contexts/ActiveThemeProvider';

export function useActiveTheme() {
  const context = useContext(ActiveThemeContext);
  if (!context) {
    return 'useActiveTheme must be used within a ActiveThemeContext';
  }
  return context;
}
